import React from "react";
import { ICourse } from "../../../assets/types/Types";

export enum TagsProps {
  DriverTraining = "DriverTraining",
  IndustrialSafety = "IndustrialSafety",
  OccupationalSafety = "OccupationalSafety",
  Training = "Training",
  SpecializedCourses = "SpecializedCourses",
}

export enum TagsPropsTranslations {
  DriverTraining = "Обучение водителей",
  IndustrialSafety = "Промышленная безопасность",
  OccupationalSafety = "Охрана труда",
  Training = "Повышение квалификации",
  SpecializedCourses = "Специализированные тесты",
}

export interface IMenuItem {
  name: string;
  ico: React.ReactNode;
  tag: TagsProps;
  defaultImage: string;
}

export interface IPagination {
  courses: ICourse[];
  countCourse: number;
}
