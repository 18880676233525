import { IMenuItem, TagsProps, TagsPropsTranslations } from "./TypesTest";

export const MenuItems: IMenuItem[] = [
  {
    name: TagsPropsTranslations.DriverTraining,
    tag: TagsProps.DriverTraining,
    ico: <i className="fas fa-car"></i>,
    defaultImage: "",
  },
  {
    name: TagsPropsTranslations.IndustrialSafety,
    tag: TagsProps.IndustrialSafety,
    ico: <i className="fas fa-hard-hat"></i>,
    defaultImage:
      "https://images.unsplash.com/photo-1552879890-3a06dd3a06c2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1122&q=80",
  },
  {
    name: TagsPropsTranslations.OccupationalSafety,
    tag: TagsProps.OccupationalSafety,
    ico: <i className="fas fa-fire-extinguisher"></i>,
    defaultImage:
      "https://images.unsplash.com/photo-1574689049868-e94ed5301745?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1028&q=80",
  },
  {
    name: TagsPropsTranslations.Training,
    tag: TagsProps.Training,
    ico: <i className="fas fa-clipboard-list"></i>,
    defaultImage:
      "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80",
  },
  {
    name: TagsPropsTranslations.SpecializedCourses,
    tag: TagsProps.SpecializedCourses,
    ico: <i className="fas fa-chalkboard-teacher"></i>,
    defaultImage:
      "https://images.unsplash.com/photo-1518133835878-5a93cc3f89e5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80",
  },
];
