import React from "react";
import {Button, Row, Col, Modal} from "antd";
import { IAdminCourses } from "../AdminCoursesList";
import {RemoveCourse} from "../../../../assets/requests/dataForPanelAdmin";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/reducers";

enum ColorCourseSuccess {
  Valid = "#f6ffed",
  NoValid = "#fff2f0",
}

interface IAdminCourseData {
  item: IAdminCourses;
  viewDeleted: boolean;
}

export const AdminCoursesListData: React.FC<IAdminCourseData> = ({ item, viewDeleted }) => {
  const [courseToRemove, setCourseToRemove] = React.useState<string>("");
  const [modalToRemoveActive, setModalToRemoveActive] = React.useState<boolean>(false);
  const [colorValidCourse, setColorValidCourse] = React.useState<string>(
    ColorCourseSuccess.Valid
  );
    const [componentDisplay, setComponentDisplay] = React.useState<any>("flex");
  const UnactivateModalToRemoveCourse = () => {
    setModalToRemoveActive(false);
  }

  const auth = useSelector((state: RootState) => state.auth);

  const OnClickRemoveCourse = () => {
    if (courseToRemove != null && courseToRemove != ""){
       Promise.resolve(
         RemoveCourse(courseToRemove)
       ).then((res) => {
         if (res.data.id !== null && res.data.id !== undefined && res.data.id !== '00000000000000000000000000000000'){
             item.isDeleted = res.data.isDeleted;
             validateDateCheck(item);
             if (!viewDeleted){
                 setComponentDisplay("none");
             }
         }
           setModalToRemoveActive(false);
           setCourseToRemove("");
       });
  }}

  const UpdateIdToRemoveCourse = () => {
    setCourseToRemove(item.id);
  }

    React.useEffect(() => {
    validateDateCheck(item);
  }, [item]);


  const validateDateCheck = (item: IAdminCourses): boolean => {
    if (item.isDeleted) {
        setColorValidCourse(ColorCourseSuccess.NoValid);
        return false;
    } else {
        setColorValidCourse(ColorCourseSuccess.Valid);
        return true;
    }
  };

    React.useEffect(() => {
        OnClickRemoveCourse();
    }, [item, courseToRemove])

  return (
    <Row
      className="coursesList"
      style={{
        margin: "15px 0",
        textAlign: "justify",
        backgroundColor: colorValidCourse,
        display: componentDisplay,
      }}
    >
      <Col
        span={6}
        style={{
          border: "0.5px solid #d9d9d9",
          padding: "0 10px",
          display: "flex",
        }}
      >
        <p
          style={{
            alignSelf: "center",
            marginBottom: 0,
            textAlign: "justify",
            fontSize: "13px",
          }}
        >
            {item.id}
          
        </p>
      </Col>
      <Col
        span={12}
        style={{
          padding: "0 10px",
          border: "0.5px solid #d9d9d9",
          display: "flex",
        }}
      >
        <p
          style={{
            alignSelf: "center",
            marginBottom: 0,
            textAlign: "justify",
            fontSize: "13px",
          }}
        >
          {item.name}
        </p>
      </Col>
        <Col
            span={3}
            style={{
                padding: "0 10px",
                border: "0.5px solid #d9d9d9",
                display: "flex",
            }}
        >
            <p
                style={{
                    alignSelf: "center",
                    marginBottom: 0,
                    textAlign: "justify",
                    fontSize: "13px",
                }}
            >
                {item.isDeleted ? "Удален" : "Активен"}
            </p>
        </Col>
      <Col
        className="btn-edit-course-box"
        span={3}
        style={{
          justifyContent: "center",
          border: "0px",
          display: "flex",
          textAlign: "center",
          backgroundColor: "rgb(240 242 245)",
        }}
      >
          {auth.roles.includes("Admin") && (
        <Button
          className="btn-edit-course"
          style={{ alignSelf: "center", marginBottom: 0, fontSize: "12px", width: "85%" }}
          onClick={() =>
            setModalToRemoveActive(true)
          }
        >
          <span style={{ fontSize: "12px" }}>{item.isDeleted ? 'Восстановить' : "Удалить"} курс</span>
        </Button>)}
      </Col>
        <Modal visible = {modalToRemoveActive} onOk = {UpdateIdToRemoveCourse} onCancel = {UnactivateModalToRemoveCourse} >
            <div style={{
                textAlign: "center"
            }}>
                Вы действительно хотите удалить курс {item.name}?
            </div>
        </Modal>
    </Row>
  );
};
