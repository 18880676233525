import packageJson from "../../../package.json";
import { AxiosResponse } from "axios";
import $api from "./http";

export const ChangeRoleUserByUserId = async (
  authId: string,
  roles: string[]
): Promise<AxiosResponse<any>> => {
  return await $api.post(`${packageJson.server}/updateRole`, { authId, roles });
};
