import $api from "./http";
import packageJson from "../../../package.json";
import { IUserGeneralInfo } from "../../store/actions/userInfo";

export const LoadData = async (idUser: string): Promise<IUserGeneralInfo> => {
  return await $api
    .get<IUserGeneralInfo>(
      `${packageJson.server}/getUserFullInfo/${idUser}?format=json`
    )
    .then((res) => res.data);
};
