import axios from "axios";
import packageJson from "../../../package.json";
import { encode } from "js-base64";
import { AxiosResponse } from "axios";
import { IAuthResponse } from "../../store/actions/authenticate";

export const auth = async (
  Email: string,
  Password: string
): Promise<AxiosResponse<IAuthResponse>> => {
  const response = await axios.post(
    `${packageJson.server}/auth?format=json`,
    { Email, Password },
    {
      headers: {
        Authorization: `Basic ${encode(`${Email}:${Password}`)}`,
      },
    }
  );

  response.data.roles = await axios
    .get(
      `${packageJson.server}/getUserById/${response.data.userId}?format=json`
    )
    .then((res) => res.data.roles);
  return response;
};
