import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { useHistory } from "react-router-dom";
import { ICourseValid } from "../../assets/types/Types";
import { IContent, ICoursePart, ITestTemplate } from "../../assets/types/Types";
import { Typography, Menu, Spin, Modal } from "antd";
import Test from "./checkInfo/Test";
import { addCourseToUser, loadCourseWithParts } from "../../assets/requests";
import { setCurrentCourseAction } from "../../store/actions/currentCourse";
import { StateFetching } from "../../store/actions/isFetching";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import packageJson from "../../../package.json";

const { Title } = Typography;

interface IIdCorse {
  courseId: string;
}
export interface treeChildren {
  title: string;
  key: string;
  isLeaf: boolean;
  icon: any | null;
  children: treeChildren[];
  item: null | any;
}

const InfoTest: React.FC<IIdCorse> = ({ courseId }) => {
  const auth = useSelector((state: RootState) => state.auth);
  const isFetching = useSelector((state: RootState) => state.isUserFetching);

  const [courseValid, setCourseValid] = React.useState<ICourseValid | null>(
    null
  );
  const [treeData, setTreeData] = React.useState<treeChildren[]>([]);
  const [selectedItem, setSelectedItem] = React.useState<any>();
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    Promise.resolve(loadCourseWithParts(courseId)).then((res) =>
      setCourseValid(res)
    );
  }, []);

  React.useEffect(() => {
    if (auth) {
      if (courseValid && courseValid.isAvailable === true) {
        addCourseToUser(courseId, auth.userId);
        dispatch(setCurrentCourseAction(courseValid?.course));
      }
      CreateTreeParts();
    }
  }, [courseValid, auth]);

  React.useEffect(() => {
    if (treeData && treeData[0]?.children) {
      setSelectedItem(treeData[0].children[0].item);
    }
  }, [treeData]);

  const CreateTreeParts = (): void => {
    let treeNodeData: treeChildren[] = [];
    if (courseValid?.course) {
      let treeRoot: treeChildren = {
        title: courseValid?.course.rootCoursePart.name,
        key: "0-0",
        isLeaf:
          courseValid?.course.rootCoursePart.childCourseParts.length === 0
            ? true
            : false,
        icon: null,
        item: courseValid?.course.rootCoursePart,
        children:
          courseValid?.course.rootCoursePart.childCourseParts.length === 0
            ? []
            : CreateChildrenTreeParts(
                courseValid?.course.rootCoursePart.childCourseParts.sort(
                  (a, b) => a.order - b.order
                ),
                "0-0"
              ),
      };

      for (let i in courseValid?.course.rootCoursePart.testTemplates) {
        let test: ITestTemplate =
          courseValid?.course.rootCoursePart.testTemplates[i];
        let testNode: treeChildren = {
          title: test.name,
          key: treeRoot.key + "-" + (i + 1),
          isLeaf: true,
          icon: null,
          item: test,
          children: [],
        };
        treeRoot.children.push(testNode);
      }

      for (let i in courseValid?.course.rootCoursePart.contents) {
        let test: IContent = courseValid?.course.rootCoursePart.contents[i];
        let testNode: treeChildren = {
          title: test.fileName,
          key: treeRoot.key + "-" + (i + 1),
          isLeaf: true,
          icon: null,
          item: test,
          children: [],
        };
        treeRoot.children.push(testNode);
      }
      treeNodeData.push(treeRoot);
    }
    setTreeData(treeNodeData);
  };

  const CreateChildrenTreeParts = (
    parts: ICoursePart[],
    key: string
  ): treeChildren[] => {
    let arr: treeChildren[] = [];
    for (let index in parts) {
      let item: ICoursePart = parts[index];

      let treeNode: treeChildren = {
        title: item.name,
        key: key + "-" + (item.order - 1),
        isLeaf:
          item.childCourseParts.length !== 0 ||
          item.contents.length !== 0 ||
          item.testTemplates.length !== 0
            ? false
            : true,
        icon: null,
        item: item,
        children:
          item.childCourseParts.length === 0
            ? []
            : CreateChildrenTreeParts(
                item.childCourseParts.sort((a, b) => a.order - b.order),
                key + "-" + (item.order - 1)
              ),
      };
      for (let i in item.testTemplates) {
        let test: ITestTemplate = item.testTemplates[i];

        let testNode: treeChildren = {
          title: test.name,
          key: treeNode.key + "-" + (i + 1),
          isLeaf: true,
          item: test,
          icon: null,
          children: [],
        };
        treeNode.children.push(testNode);
      }
      for (let i in item.contents) {
        let test: IContent = item.contents[i];
        let testNode: treeChildren = {
          title: test.fileName,
          key: treeNode.key + "-" + (i + 1),
          isLeaf: true,
          item: test,
          icon: null,
          children: [],
        };
        treeNode.children.push(testNode);
      }
      arr.push(treeNode);
    }
    return arr;
  };
  const handleClick = (e: any): void => {
    treeData[0].children.map((key) => {
      if (e.key === key.key) {
        setSelectedItem(key.item);
      }
    });
  };

  const testInNotValid = () => {
    Modal.confirm({
      title: "Ссылка не действительна",
      icon: <ExclamationCircleOutlined />,
			//@ts-ignore
      content: (<div> Для уточнения информации обратитесь в службу технической поддержки по номеру: 80296686025<br/>
        Время работы с 10:00 до 21:00.</div>),
      okText: "Ок",
      cancelButtonProps: {
        style: {
          opacity: 0,
        },
      },
    });

    history.push("/");
  };

  const userIsNotAuth = () => {
    Modal.confirm({
      title: "Пользователь не авторизован",
      icon: <ExclamationCircleOutlined />,
      content: `Для прохождения нужно авторизоваться!`,
      okText: "Ок",
      cancelButtonProps: {
        style: {
          opacity: 0,
        },
      },
    });

    history.push("/tests");
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {courseValid && !courseValid.isAvailable ? (
        testInNotValid()
      ) : isFetching === StateFetching.Loaded && courseValid ? (
        <div className="container base-container struct-course-page">
          <div className="struct-course">
            <Title className="h2-info-course" level={3}>
              {courseValid?.course?.rootCoursePart.name}
            </Title>
            <div
              className="menu-sruct-test"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <Menu
                defaultSelectedKeys={["0-0"]}
                onClick={handleClick}
                style={{ width: "100%" }}
                className="menu-struct-test"
              >
                {treeData &&
                  treeData[0]?.children &&
                  treeData[0]?.children?.map((title) => (
                    <Menu.Item key={title.key}>{title.title}</Menu.Item>
                  ))}
              </Menu>
            </div>
          </div>
          <div className="info-course">
            <Title
              className="h2-info-course"
              level={2}
              style={{ marginTop: "10px" }}
            >
              {courseValid?.course?.name}
            </Title>
            <hr />
            {selectedItem && (
              <Test item={selectedItem} files={courseValid.files} />
            )}
          </div>
        </div>
      ) : isFetching === StateFetching.Default ||
        isFetching === StateFetching.Loading ? (
        <Spin className="preloader" tip="Loading..." />
      ) : (
        isFetching === StateFetching.None && userIsNotAuth()
      )}
    </>
  );
};

export default InfoTest;
