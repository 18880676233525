export const data = [
  {
    title: "Обучение в индивидуальном темпе",
    text: "Скорость изучения устанавливается самим учащимся в зависимости от его личных обстоятельств и потребностей",
  },
  {
    title: "Свобода и гибкость",
    text: "Учащийся может выбрать любой из многочисленных курсов обучения, а также самостоятельно планировать время, место и продолжительность занятий",
  },
  {
    title: "Доступность",
    text: "Независимость от географического и временного положения обучающегося и образовательного учреждения позволяет не ограничивать себя в образовательных потребностях",
  },
  {
    title: "Мобильность",
    text: "Эффективная реализация обратной связи между преподавателем и обучаемым является одним из основных требований и оснований успешности процесса обучения",
  },
  {
    title: "Технологичность",
    text: "Использование в образовательном процессе новейших достижений информационных и телекоммуникационных технологий",
  },
  {
    title: "Социальное равноправие",
    text: "Равные возможности получения образования независимо от места проживания, состояния здоровья, элитарности и материальной обеспеченности обучаемого",
  },
];