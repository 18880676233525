import React from "react";
import { PdfViewerComponent } from "./PdfViewerComponent";

interface IHelpInfoProp {
  id: string;
}

export const HelpInfo: React.FC<IHelpInfoProp> = ({ id }) => {
  const onLoadError = (error: any) => {
    console.log("onLoadError", error);
  };

  const [numPages, setNumPages] = React.useState(null);
  const onDocumentLoadSuccess = React.useCallback(
    ({ numPages }) => {
      setNumPages(numPages);
    },
    [setNumPages]
  );

  return (
    <>
      <PdfViewerComponent id={id} />
    </>
  );
};
