import React from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";

import img1 from "../../assets/images/carousel/1_1.jpg";
import img2 from "../../assets/images/carousel/2_2.jpg";
import img3 from "../../assets/images/carousel/3_3.jpg";
import img4 from "../../assets/images/carousel/4_4.jpg";

const Slider: React.FC = () => {
  return (
    <div>
      <AwesomeSlider
        style={{
          width: "70%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "20px",
        }}
      >
        <div key={1} data-src={img1} />
        <div key={2} data-src={img2} />
        <div key={3} data-src={img3} />
        <div key={4} data-src={img4} />
      </AwesomeSlider>
    </div>
  );
};

export default Slider;
