import React from "react";
import { Result } from "antd";

const DownloadingError: React.FC = () => {
  return (
    <Result
      status="error"
      title="Упс!.."
      subTitle="Не удалось загрузить данные"
    />
  );
};

export default DownloadingError;
