import React from "react";
import { ICourse } from "../../../assets/types/Types";
import { useHistory } from "react-router-dom";
import { Card, Button } from "antd";

import "antd/dist/antd.css";

const { Meta } = Card;

interface TestItemProps {
  item: ICourse;
}

const TestItem: React.FC<TestItemProps> = ({ item }) => {
  function getRandomArbitrary(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  const viewImageCardTestRandom = (): string => {
    let rand = getRandomArbitrary(1, 7);
    return `/images/img-test_${rand}.png`;
  };

  const history = useHistory();

  return (
    <div className="btn-select-test">
      <Card
        cover={<img alt="example" src={viewImageCardTestRandom()} />}
        actions={[
          <Button
            type="primary"
            style={{ width: "100%", margin: 0 }}
            title={"Пройти тест"}
            onClick={() => history.push(`/course/id:${item.courseIdHash}`)}
            size={"large"}
          >
            Выбрать курс
          </Button>,
        ]}
      >
        <Meta
          title={item.name}
        />
      </Card>
    </div>
  );
};

export default TestItem;
