import ReactDOM from 'react-dom';
import store from './store/store';
import { Provider } from 'react-redux';
import {BrowserRouter } from "react-router-dom";
import App from './App';
import './assets/scss/app.scss';
// @ts-ignore
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
// @ts-ignore
import AlertTemplate from 'react-alert-template-basic';

const options = {
    // you can also just use 'bottom center'
    position: positions.BOTTOM_RIGHT,
    timeout: 5000,
    // you can also just use 'scale'
    transition: transitions.SCALE
}

ReactDOM.render(
    <AlertProvider template={AlertTemplate} {...options}>
        <BrowserRouter>
            <Provider store={store}>
                <App/>
            </Provider>
        </BrowserRouter>
    </AlertProvider>,
    document.getElementById('root')
);
