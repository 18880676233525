import packageJson from "../../../package.json";
import { AxiosResponse } from "axios";
import { IUser } from "../../components/adminRoom/Users/UserList";
import { IRole } from "../../components/adminRoom/Users/UserList";
import $api from "./http";
import {IRemoveUserResponse} from "../types/Types";
import {IAdminCourses} from "../../components/adminRoom/AdminCourse/AdminCoursesList";

export const GetAllUsers = async (): Promise<AxiosResponse<IUser[]>> => {
  return await $api.get<IUser[]>(
    `${packageJson.server}/json/reply/getAllUsers`
  );
};

export const GetAllRoles = async (): Promise<AxiosResponse<IRole[]>> => {
  return await $api.get<IRole[]>(
    `${packageJson.server}/json/reply/getAllRoles`
  );
};

export const RemoveUser = async (email: string): Promise<AxiosResponse<IRemoveUserResponse>> => {
  return await $api.post(
    `${packageJson.server}/removeUser?Email=${email}`);
};

export const RemoveCourse = async (courseId: string): Promise<AxiosResponse<IAdminCourses>> => {
  return await $api.post(
      `${packageJson.server}/UpdateDeleteStatus`, {id: courseId});
};
