import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ElementNotFound from "../exceptionHandling/notFound/ElementNotFound";
import ListIsEmpty from "../exceptionHandling/notFound/ListIsEmpty";
import DownloadingError from "../exceptionHandling/DownloadingError";
import ImpossibleElement from "../exceptionHandling/ImpossibleElement";

export interface DownloadableElementProps {
  Сomponent: React.FunctionComponent<{ params: any }>;
  params: any;
  state: DownloadStates;
}

export enum DownloadStates {
  Undefined,
  Downloading,
  Error, // exception
  NoData, // null
  Empty, // empty array
  OK,
}

const DownloadableElement: React.FC<DownloadableElementProps> = ({
  Сomponent,
  params,
  state,
}) => {
  switch (state) {
    case undefined:
      return <ImpossibleElement />;
    case DownloadStates.Undefined:
      return <></>;
    case DownloadStates.Downloading:
      return (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 50 }} />}
          style={{ marginTop: 100, width: "100%" }}
        />
      );
    case DownloadStates.Error:
      return <DownloadingError />;
    case DownloadStates.NoData:
      return <ElementNotFound />;
    case DownloadStates.Empty:
      return <ListIsEmpty />;
    case DownloadStates.OK:
      return <Сomponent params={params} />;
    default:
      throw new Error("unknown state");
  }
};

export default DownloadableElement;
