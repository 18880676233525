import React from "react";

import { Typography, Row, Col } from "antd";
import "antd/dist/antd.css";

import OfficeDescription from "./description/OfficeDescription";

const { Title } = Typography;

const styleFooter = {
  marginTop: "50px",
  paddingTop: "25px",
  backgroundColor: "#F9F9F9",
};

const styleSidePadding = {
  paddingLeft: "50px",
  paddingRight: "50px",
};

const Footer: React.FC = () => {
  return (
    <footer style={styleFooter}>
      <Row className="footer-container" justify="space-between">
        <Col flex="1 1 50%" style={{ minWidth: "min(100%, 350px)" }}>
          <Title
            level={2}
            style={{
              padding: "10px 0px 25px 0px",
              textAlign: "center",
              wordWrap: "unset",
            }}
          >
            ПромТехОбразование
          </Title>
        </Col>

        <Col flex="1 1 50%" style={styleSidePadding}>
          <Row wrap={true}>
            <OfficeDescription
              city="г. Минск"
              adress="220012, ул. Чернышевского, д. 10, кабинет 75"
              phones={[
                "+375 29 686-60-25",
                "+375 33 686-60-25",
                "+375 17 397-60-25",
                "+375 17 290-49-98",
              ]}
              mail="info@pto.by"
              worktime="Работаем Пн-Пт с 8:30 до 17:30"
            />
            <OfficeDescription
              city="г. Бобруйск"
              adress="213826, ул. К. Либкнехта, д. 77"
              phones={[
                "+375 29 196-60-25",
                "+375 33 626-60-25",
                "+375 225 72-50-12",
              ]}
              mail="bobr@pto.by"
              worktime="Работаем Пн-Пт с 8:30 до 17:30"
            />
          </Row>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
