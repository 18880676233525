import {
  AuthActions,
  CLEAR_AUTH_INFO,
  IAuthResponse,
  SET_AUTH_INFO,
} from "../actions/authenticate";

const initialState: IAuthResponse | null = null;

export const authReducer = (
  state: IAuthResponse | null = initialState,
  action: AuthActions
) => {
  switch (action.type) {
    case SET_AUTH_INFO:
      return {
        refreshToken: action.payload?.refreshToken,
        bearerToken: action.payload?.bearerToken,
        userId: action.payload?.userId,
        sessionId: action.payload?.sessionId,
        userName: action.payload?.userName,
        displayName: action.payload?.displayName,
        roles: action.payload?.roles,
      };

    case CLEAR_AUTH_INFO:
      return null;
    default:
      return state;
  }
};
