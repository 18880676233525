import React from "react";
import { Result } from "antd";

const ElementNotFound: React.FC = () => {
  return (
    <Result
      status="warning"
      title="Упс!.. 404"
      subTitle="По запросу ничего не найдено"
    />
  );
};

export default ElementNotFound;
