import React from "react";
import { Typography } from "antd";
import CardsContent from "./CardsContent";
import StepsStudying from "./StepsStudying";
const { Title, Paragraph, Text } = Typography;

const MainPageContent: React.FC = () => {
  return (
    <div className="container base-container">
      <div className="info">
        <Title 
        style={{
          marginRight: "auto",
          marginLeft: "auto",
        }}
        level={1}>Как это работает</Title>
        <Paragraph>
          <Text strong>
            Система дистанционного обучения ПромТехОбразование{" "}
          </Text>
          - это форма обучения позволяющая получить современные и востребованные
          знания без отрыва от места работы и места проживания, используя новые
          информационно-коммуникационные технологии и Интернет.
        </Paragraph>
        <CardsContent />
        <StepsStudying />
      </div>
    </div>
  );
};

export default MainPageContent;
