import packageJson from "../../../package.json";
import { IOrganization, IResponceImage } from "../types/Types";
import $api from "./http";

export const loadOrganization = (): Promise<IOrganization[]> => {
  return $api
    .get<IOrganization[]>(`${packageJson.server}/getorganization?format=json`)
    .then((res) => res.data);
};

export const saveEditData = (userInfo: {
  firstName: string | undefined;
  name: string | undefined;
  fathersName: string | undefined;
  birthDate: any;
  gender: string | undefined;
  email: string | undefined;
  phoneNumber: string | undefined;
  address: string | undefined;
  displayName: string | undefined;
  roles: string[] | undefined;
  passport: {
    serialAndNumber: string | undefined;
    identif: string | undefined;
    dateOfIssue: any;
    issuedOrganization: string | undefined;
  };
  authId: string;
}): void => {
  $api.post(
    `${packageJson.server}/editProfileForUser`,
    JSON.stringify({
      userInfo: userInfo,
    }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const saveEditAvatar = (
  avatarUser: {
    id: string | undefined;
    blobData: string | undefined | null;
    contentType: string | undefined | null;
  },
  authId: string
): Promise<IResponceImage> => {
  return $api
    .post(
      `${packageJson.server}/editAvatarForUser`,
      JSON.stringify({
        avatarUser: avatarUser,
        authId: authId,
      }),
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then((res) => res.data);
};
