import React from "react";
import { Form, Input, Button, Checkbox } from "antd";
// @ts-ignore
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { ChangeOldPassword } from "../../assets/requests";
import { useHistory } from "react-router-dom";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const ChangePassword: React.FC = () => {
  let auth = useSelector((state: RootState) => state.auth);

  const [oldPassword, setOldPassword] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [repllyPasword, setRepllyPassword] = React.useState<string>("");
  const [inputType, SetPasswordsVisibility] = React.useState<string>("password");
  const [componentSize, setComponentSize] = React.useState<SizeType | "middle">(
    "middle"
  );

  const alert = useAlert();
  const history = useHistory();

  type SizeType = Parameters<typeof Form>[0]["size"];

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };
  const PasswordHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setOldPassword(e.target.value);
  };

  const NewPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setNewPassword(e.target.value);
  };

  const RepllyPasswordHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRepllyPassword(e.target.value);
  };

  const IsValidPassword = (password: string, newPassword: string): boolean => {
    const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
    if (password === newPassword) {
      alert.error("Новый пароль не должен совподать со старым");
      return false;
    }
    if (!regex.test(newPassword)) {
      alert.error(
        "Пароль должен содержать минимум 1 заглавную букву, 1 цифру, и быть не менее 8 символов"
      );
      return false;
    }
    return true;
  };

  const SaveNewPassword = (
    password: string,
    newPassword: string,
    repllyPassport: string
  ) => {
    if (password !== "" && newPassword !== "" && repllyPassport !== "") {
      if (
        IsValidPassword(password, newPassword) &&
        newPassword === repllyPassport &&
        auth
      ) {
        ChangeOldPassword(auth.userName, password, newPassword).then((res) => {
          console.log(res.result);
          if (res.result) {
            alert.success("Пароль изменен");
            history.push("/userRoom/personalArea");
          } else {
            alert.error("Старый пароль неверный");
          }
        });
      } else if (newPassword !== repllyPassport) {
        alert.error("Пароли не совпадают");
        return false;
      } else {
        alert.error("Проверьте вводимые данные");
        return false;
      }
    } else {
      alert.error("Заполните все поля");
    }
    return false;
  };

  return (
    <div className="container">
      <Form
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 5 }}
        layout="horizontal"
        style={{ marginTop: "15px" }}
        initialValues={{ size: componentSize }}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
      >
        <h1>Смена пароля</h1>
        <Form.Item label="Старый пароль: ">
          <Input
            type={inputType}//{"password"}
            onChange={(e) => {
              PasswordHandler(e);
            }}
          />
        </Form.Item>
        <Form.Item label="Новый пароль: ">
          <Input
            type={inputType}//{"password"}
            onChange={(e) => {
              NewPasswordHandler(e);
            }}
          />
        </Form.Item>
        <Form.Item label="Повторите пароль: ">
          <Input
            type={inputType}//{"password"}
            onChange={(e) => {
              RepllyPasswordHandler(e);
            }}
          />
        </Form.Item>
        <Form.Item label="Показать пароли: ">
          <Checkbox
            onChange={(e) => {
              if (e.target.checked == true){
                SetPasswordsVisibility("input");
              }
              else {
                SetPasswordsVisibility("password");
              }
            }}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              SaveNewPassword(oldPassword, newPassword, repllyPasword);
            }}
          >
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePassword;
