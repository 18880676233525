import React from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Divider,
  Checkbox,
  Modal,
  Upload,
} from "antd";
import {
  getCourseForEdit,
  setNewDataCourse,
  deleteCourseByHashId,
  addFileInfoForCourse,
  deleteFileInfoForCourse,
  changeFileInfoForCourse,
} from "../../../assets/requests/courses";
import moment, { Moment } from "moment";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useHistory } from "react-router";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { ICoursesWithLinkAndFiles } from "../../../assets/types/Types";

interface ICourseId {
  id: number;
}

interface IFileInfo {
  data: string;
  type: string;
}

const CourseEdit: React.FC<ICourseId> = ({ id }) => {
  const [course, setCourse] = React.useState<
    ICoursesWithLinkAndFiles | undefined
  >();
  const [link, setLink] = React.useState<string | undefined>();
  const [idCourse, setIdCourse] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [lifeTime, setLifeTime] = React.useState<number | string
  >(14);
  const [hashId, setHashId] = React.useState<string>("");
  const [anyInfo, setAnyInfo] = React.useState<string>("");
  const [tags, setTags] = React.useState<string[]>([]);
  const [checkedList, setCheckedList] = React.useState<
    string[] | CheckboxValueType[]
  >(tags);
  const [disabledDate, setDisabledDate] = React.useState<boolean>(false);
  const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
  const [checkBoxTime, setCheckBoxTime] = React.useState<boolean>(false);
  const [isModalVisibleAddFile, setIsModalVisibleAddFile] =
    React.useState<boolean>(false);
  const [disabledForAdd, setDisabledForAdd] = React.useState<boolean>(false);
  const [defaultValue, setDefaultValue] = React.useState<string>("");
  const [addFileInfo, setAddFileInfo] = React.useState<string>("");
  const [fileInfo, setFileInfo] = React.useState<IFileInfo>();
  const [fileInfoChange, setFileInfoChange] = React.useState<IFileInfo>();
  const [isModalVisibleChangeFile, setIsModalVisibleChangeFile] =
    React.useState<boolean>(false);
  const [changeFile, setChangeFileInput] = React.useState<string>("");
  const [changeFileiD, setChangeFileiD] = React.useState<string>("");
  const [createdDate, setCreatedDate] = React.useState<string>(moment().toString());

  const options = [
    { label: "Обучение водителей", value: "DriverTraining" },
    { label: "Промышленная безопасность", value: "IndustrialSafety" },
    { label: "Охрана труда", value: "OccupationalSafety" },
    { label: "Повышение квалификации", value: "Training" },
    { label: "Специализированные тесты", value: "SpecializedCourses" },
  ];

  const history = useHistory();

  React.useEffect(() => {
    Promise.resolve(getCourseForEdit(id)).then((res) => setCourse(res));
  }, []);

  React.useEffect(() => {
    if (course) {
      course?.coursesWithLink?.id && setIdCourse(course.coursesWithLink.id);
      course?.coursesWithLink?.link && setLink(course.coursesWithLink.link);
      course?.coursesWithLink?.name && setName(course.coursesWithLink.name);
      course?.coursesWithLink?.createdDate && setCreatedDate(course.coursesWithLink.createdDate)
      if (course?.coursesWithLink?.lifeTime) {
          setLifeTime(course?.coursesWithLink.lifeTime);
      } else {
        setLifeTime(course?.coursesWithLink.lifeTime);
        setCheckBoxTime(true);
        setDisabledDate(true);
      }
      course?.coursesWithLink?.note && setAnyInfo(course.coursesWithLink.note);
      course?.coursesWithLink?.tags && setTags(course.coursesWithLink.tags);
      course?.coursesWithLink?.courseIdHash &&
        setHashId(course.coursesWithLink.courseIdHash);
    }
  }, [course]);

  React.useEffect(() => {
    if (course) setCheckedList(course.coursesWithLink.tags);
  }, [tags]);

  const onChange = (list: CheckboxValueType[]) => setCheckedList(list);

  const onChangeCheckDateActive = (checkedValue: any) => {
    if (checkedValue.target.checked === true) {
      setDisabledDate(true);
      setCheckBoxTime(true);
      setLifeTime(0);
    } else {
      setCheckBoxTime(false);
      setDisabledDate(false);
      setLifeTime(14);
    }
  };

  const onClickSaveNewDataCourse = () => {
    if (disabledDate === false) {
      if (
        lifeTime === '' || lifeTime < 0
      ) {
        Modal.confirm({
          title: "Неверный срок действия курса",
          icon: <ExclamationCircleOutlined />,
          content: `Для редактирования нужно ввести число, не больше чем 0`,
          okText: "Ок",
          cancelButtonProps: {
            style: {
              opacity: 0,
            },
          },
        });
      } else {
        Promise.resolve(
          setNewDataCourse(
            idCourse,
            anyInfo,
            lifeTime,
            checkedList,
            name,
            hashId,
            new Date(moment(createdDate).toString()),
            id,
          )
        ).finally(() => {
          history.push("/adminRoom/");
        });
      }
    } else {
      Promise.resolve(
        setNewDataCourse(idCourse, anyInfo, 0, checkedList, name, hashId, new Date(moment(createdDate).toString()), id )
      ).finally(() => {
        history.push("/adminRoom/");
      });
    }
  };

  const handleOk = () => {
    Promise.resolve(deleteCourseByHashId(id));
    setVisibleModal(false);
    history.push("/adminRoom/");
  };

  const handleVisibleModalAddFile = () => {
    setIsModalVisibleAddFile(true);
  };
  const handleOkAddFile = () => {
    if (addFileInfo !== "" && fileInfo) {
      Promise.resolve(
        addFileInfoForCourse({
          categoryName: addFileInfo,
          fileType: fileInfo.type,
          file: fileInfo.data,
          courseId: course?.coursesWithLink.id || "",
        })
      ).then(() => {
        setIsModalVisibleAddFile(false);
        Promise.resolve(getCourseForEdit(id)).then((res) => setCourse(res));
      });
    }
  };

  const handleOkChangeFile = () => {
    if (changeFile !== "") {
      Promise.resolve(
        changeFileInfoForCourse({
          categoryName: changeFile,
          fileType: fileInfoChange?.type || "",
          file: fileInfoChange?.data || "",
          fileId: changeFileiD,
        })
      ).then(() => {
        setIsModalVisibleChangeFile(false);
        Promise.resolve(getCourseForEdit(id)).then((res) => setCourse(res));
      });
    }
  };

  const showModal = () => {
    setVisibleModal(true);
  };

  const handleCancel = () => {
    setIsModalVisibleAddFile(false);
		setAddFileInfo('');
		setDefaultValue('');

  };

  const handleCancelCahnge = () => {
    setIsModalVisibleChangeFile(false);
		
  };

  const hideModal = () => {
    setVisibleModal(false);
  };

  const deleteFileInfo = (index: string) => {
    deleteFileInfoForCourse(index).then(() => {
      Promise.resolve(getCourseForEdit(id)).then((res) => setCourse(res));
    });
  };

  const showFileInfo = (id: string) => {
    history.push(`/helpInfo/id:${id}`);
  };

  const changeFileInfo = (id: string) => {
    setChangeFileInput(
      course?.learningFiles.find((item) => item.fileId === id)?.categoryName ||
        ""
    );
    setChangeFileiD(id);
    setIsModalVisibleChangeFile(true);
  };

  const disabledDates = (current : Moment) => {
    return current && current < moment().endOf('day');
  };

  const handleAddFile = (item: any) => {
    const { status } = item.file;
    if (status === "done") {
      const reader = new FileReader();
      reader.readAsDataURL(item.file.originFileObj);
      reader.onloadend = () => {
        if (reader.result)
          setFileInfo({
            data: reader.result.toString().split(",")[1],
            type: reader.result.toString().split(",")[0],
          });
      };
    }
  };

  const handleChangeFile = (item: any) => {
    const { status } = item.file;
    if (status === "done") {
      const reader = new FileReader();
      reader.readAsDataURL(item.file.originFileObj);
      reader.onloadend = () => {
        if (reader.result)
				setFileInfoChange({
            data: reader.result.toString().split(",")[1],
            type: reader.result.toString().split(",")[0],
          });
      };
    }
  };
  const handleChangeLifeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value.replace(/\D/g, '');
    if (val)
    {
      setLifeTime(parseInt(val));
    }
    else {
      setLifeTime('');
    }
  };
  return (
    <>
      {course && (
        <div className="container base-container">
          <Divider orientation="left">
            <span style={{ fontWeight: "bold" }}>Редактирование курса</span>
          </Divider>
          <Form
            size="large"
            labelAlign="left"
            labelCol={{ span: 3 }}
            initialValues={{ remember: true }}
          >
            <Form.Item label="Ссылка">
              <Input
                value={link}
                onChange={(e) => {
                  setLink(e.currentTarget.value);
                }}
              />
            </Form.Item>
            <Form.Item label="Название">
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Item>
            <Form.Item label="Доп. информация">
              <Input.TextArea
                value={anyInfo}
                onChange={(e) => setAnyInfo(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Курс доступен:">
                  <input
                      type="text"
                      placeholder="Кол. дней"
                      value={lifeTime}
                      onChange={(value) => handleChangeLifeTime(value)}
                      disabled={disabledDate}
                  />
              <Checkbox
                style={{ marginLeft: "10px" }}
                checked={checkBoxTime}
                onChange={onChangeCheckDateActive}
              >
                Ссылка всегда одна
              </Checkbox>
            </Form.Item>
            <Form.Item label="Категория">
              <Checkbox.Group
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 8fr)",
                }}
                value={checkedList}
                options={options}
                onChange={onChange}
              ></Checkbox.Group>
            </Form.Item>
            <Form.Item label="Доп. файлы">
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginBottom: "10px" }}
                disabled={course?.learningFiles?.length >= 3 ? true : false}
                onClick={handleVisibleModalAddFile}
              >
                Добавить
              </Button>
              {course.learningFiles &&
                course.learningFiles.map((item, index) => (
                  <div className="second-file-info" key={index}>
                    <p>Название</p>
                    <Input value={item.categoryName} readOnly />
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => showFileInfo(item.fileId)}
                    >
                      Просмотр
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginLeft: "15px" }}
                      onClick={() => changeFileInfo(item.fileId)}
                    >
                      Перезаписать
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginLeft: "15px" }}
                      danger
                      onClick={() => deleteFileInfo(item.fileId)}
                    >
                      Удалить
                      <DeleteOutlined
                        style={{ position: "relative", top: "-3px" }}
                      />
                    </Button>
                  </div>
                ))}
            </Form.Item>
            <div style={{ display: "flex" }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={onClickSaveNewDataCourse}
              >
                Сохранить
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: "15px" }}
                danger
                onClick={showModal}
              >
                Удалить
                <DeleteOutlined style={{ position: "relative", top: "-3px" }} />
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: "auto" }}
                onClick={() => history.push("/adminRoom")}
              >
                Закрыть
              </Button>
            </div>
          </Form>
          <Modal
            title="Удаление"
            visible={visibleModal}
            onOk={handleOk}
            onCancel={hideModal}
            okText="Да"
            cancelText="Нет"
          >
            <p>Вы точно хотите удалить курс?</p>
          </Modal>
          <Modal
            title="Добавление доп. файла"
            visible={isModalVisibleAddFile}
						onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Отмена
              </Button>,
              <Button key="submit" type="primary" onClick={handleOkAddFile}>
                Добавить
              </Button>,
            ]}
          >
            <div className="add-name-for-file">
              <p>Название</p>
              <Input
                value={addFileInfo}
                onChange={(e) => {
                  setAddFileInfo(e.currentTarget.value);
                }}
              />
            </div>
            <div className="add-file">
              <Upload
                disabled={disabledForAdd}
                action={defaultValue}
                listType={"text"}
                className=""
                maxCount={1}
                onChange={handleAddFile}
              >
                <Button
                  style={{ alignSelf: "center", marginRight: "15px" }}
                  size="middle"
                  disabled={disabledForAdd}
                  icon={<UploadOutlined />}
                >
                  Выбрать
                </Button>
              </Upload>
            </div>
          </Modal>
          <Modal
            title="Редактирование доп. файла"
            visible={isModalVisibleChangeFile}
						onCancel={handleCancelCahnge}
            footer={[
              <Button key="back" onClick={handleCancelCahnge}>
                Отмена
              </Button>,
              <Button key="submit" type="primary" onClick={handleOkChangeFile}>
                Сохранить
              </Button>,
            ]}
          >
            <div className="add-name-for-file">
              <p>Название</p>
              <Input
                value={changeFile}
                onChange={(e) => {
                  setChangeFileInput(e.currentTarget.value);
                }}
              />
            </div>
            <div className="add-file">
              <Upload
                disabled={disabledForAdd}
                action={defaultValue}
                listType={"text"}
                className=""
                maxCount={1}
                onChange={handleChangeFile}
              >
                <Button
                  style={{ alignSelf: "center", marginRight: "15px" }}
                  size="middle"
                  disabled={disabledForAdd}
                  icon={<UploadOutlined />}
                >
                  Выбрать
                </Button>
              </Upload>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default CourseEdit;
