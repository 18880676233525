import { ReactElement } from "react";

export interface IItemsNav {
  name: string;
  path: string;
  isVisible: boolean;
}

export interface ICoursePart {
  id: string;
  name: string;
  order: number;
  level: number;
  image: string;
  parentCoursePartId: string;
  childCourseParts: ICoursePart[];
  testTemplates: ITestTemplate[];
  contents: IContent[];
}
export interface IContent {
  id: string;
  fileName: string;
  info: string;
  type: string;
  file: string;
}
export interface ITestTemplate {
  id: string;
  coursePartId: string;
  name: string;
  testType: string;
  commonTestSettings: ICommonTestSettings;
  examTestSettings: IExamTestSettings;
  trainingTestSettings: ITrainingTestSettings;
  image: string;
  tags: string[];
}

export interface ICommonTestSettings {
  countOfQuestions: number;
  isRandomQuestions: boolean;
  isRandomAnswers: boolean;
  tags: string[];
  roles: string[];
  newQuestionPriority: boolean;
  errorQuestionPriority: boolean;
}

export interface IExamTestSettings {
  testTime: number;
  elapsedTestTimeFailsTest: boolean;
  questionTime: number;
  elapsedQuestionTimeFailsTest: boolean;
  maxDifficulty: number;
  minDifficulty: number;
  allowCustomAnswerOrder: boolean;
  showTips: boolean;
  showAnswerValidationResults: boolean;
  testEstimationStrategy: string;
  estimationStrategyValue: number;
}

export interface ITrainingTestSettings {
  tags: string[];
  disableStatistics: boolean;
}

export interface ICourse {
  id: string;
  name: string;
  tags: string[];
  defenition: string;
  image: string;
  price: number;
  duration: number;
  days: number;
  skils: string[];
  goods: string[];
  courseParts: ICoursePart[];
  rootCoursePart: ICoursePart;
  blobImage: IImage;
  courseIdHash: string;
}

export interface ICourseValid {
  course: ICourse;
  isAvailable: boolean;
  files: IFileInfo[];
}

export interface IImage {
  id: string;
  blobData: string;
}

export interface IFileInfo {
  categoryName: string;
  fileId: string;
}

export interface IStatsTest {
  questionData: string;
  rightAnswerData: string;
  userAnswer: string;
  isRight: boolean;
  image: IImage;
}

export interface IEmailCheck {
  isUserNotExists: boolean;
}
export interface IRegistrationUser {
  success: boolean;
}
export interface ICheckUserAnswer {
  rightAnswerGiven: boolean;
  questionRightAnswer: string;
}

export interface IAnswer {
  id: string;
  questionId: string;
  answerData: string;
  image: string;
  isRight: boolean;
  order: number;
}

export interface IOrganization {
  organizationName: string;
}
export interface IResponceImage {
  result: boolean;
}

export interface ICoursesWithLinkAndFiles {
  coursesWithLink: ICoursesWithLink;
  learningFiles: ICoursesWithLinkFiles[];
}

export interface ICoursesWithLink {
  id: string;
  uniqId: number;
  link: string;
  name: string;
  tags: string[];
  createdDate: string;
  note: string;
  courseIdHash: string;
  lifeTime: number;
}

export interface ICoursesWithLinkFiles {
  categoryName: string;
  fileId: string;
}

export interface IDataImage {
  name: string;
  data: string;
  type: string;
}

export interface IdStatusLoadCourse {
  id: string;
  message: string | undefined;
}

export interface IIsLoadingCourse {
  status: string;
  message: string;
  link: string;
}

export interface IStateCheckAutoDelete {
  flag: boolean;
}

export interface IAddFileInfo {
  courseId: string;
  fileType: string;
  file: string;
  categoryName: string;
}

export interface IChangeFileInfo {
  fileId: string;
  fileType: string;
  file: string;
  categoryName: string;
}

export interface IFileInfoPdf {
  file: string;
  fileType: string;
}
export interface IResPassword {
  result: boolean;
}

export enum TypePaid {
  Paid = "Paid",
  Started = "Started",
  Ended = "Ended",
  NotPaid = "NotPaid",
}

export interface TypeCourseEvent {
  id: number;
  authId: number;
  courseId: string;
  eventDateTime: string;
  courseEventState: TypePaid;
}

export interface ICreateTestForUserCourse {
  testEventId: number;
}

export interface IImgQuestion {
  blobData: string;
  contentType: string;
  id: string;
}

export interface IQuestion {
  id: string;
  questionData: string;
  questionType: string;
  answers: IAnswer[];
  serialNumber: number;
  image: IImgQuestion;
}

export interface IAnswer {
  id: string;
  questionId: string;
  answerData: string;
  image: string;
  isRight: boolean;
  order: number;
}

export interface IPartStat {
  namePart: string;
  eventStat: ITestStat[];
}
export interface ITestStat {
  nameTest: string;
  stat: IEventStat[];
}

export interface IEventStat {
  eventId: number;
  result: number;
}

export interface CheckoutCourseForUser {
  courseId: string;
}

export interface StatProp {
  courseId: string;
  userID: string;
}

export interface ITreeStatistic {
  key: string;
  title: string | ReactElement;
  children: ITreeStatistic[];
}

export interface IRemoveUserResponse{
  message: string;
}