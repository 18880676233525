import React from "react";
import { Row, Col, Pagination, Input, Button, Radio } from "antd";
import { SortAscendingOutlined } from "@ant-design/icons";
import { UserData } from "./UserListComponent/UserData";
import { GetAllUsers, GetAllRoles } from "../../../assets/requests/dataForPanelAdmin";

export interface IUser {
  authId: string;
  email: string;
  displayName: string;
  roles: string[];
}

export interface IRole {
  id: string;
  rolesName: string;
}

export const UserList: React.FC<any> = () => {
  const [userList, setUserList] = React.useState<IUser[]>();
  const [roleList, setRoleList] = React.useState<IRole[]>();
  const [coursesPerPage, setCoursesPerPage] = React.useState<number>(10); //сколько курсов отображается на странице
  const [currentPage, setCurrentPage] = React.useState<number>(1); //текущая страница
  const [arrayForPagination, setArrayForPagination] = React.useState<IUser[]>();
  //const [fullArrayForPagination, setFullArrayForPagination] = React.useState<IUser[]>();
  const [selectedUsersType, setSeletedUserTypes] = React.useState("User");
  const [arraySearch, setArraySearch] = React.useState<IUser[]>();
  const [stateFilterName, setStateFilterName] = React.useState<boolean>(false);
  const [stateFilterEmail, setStateFilterEmail] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    Promise.resolve(GetAllUsers()).then((json) => setUserList(json.data));

    Promise.resolve(GetAllRoles()).then((json) => setRoleList(json.data));
    const pagination = localStorage.getItem("paginationUsers");
    if (!pagination) {
      localStorage.setItem("paginationUsers", "1");
    }
  }, []);

  React.useEffect(() => {
    if (arraySearch) {
      let lastIndex = currentPage * coursesPerPage;
      let firstIndex = lastIndex - coursesPerPage;
      setArrayForPagination(arraySearch?.slice(firstIndex, lastIndex));
      //setFullArrayForPagination(userList);
      const pagination = localStorage.getItem("paginationUsers");
      if (pagination) {
        setCurrentPage(Number(pagination));
      }
    }
  }, [userList, currentPage, arraySearch]);

  React.useEffect(() => {
    if (userList) {
      setArraySearch(userList);
    }
    const pagination = localStorage.getItem("paginationUsers");
    if (pagination) {
      setCurrentPage(Number(pagination));
    }
  }, [userList]);

  const handlePaginationChange = (
    page: number,
    size: number | undefined
  ): void => {
    if (!size) return;
    
    if (size === coursesPerPage) {
      setCurrentPage(page);
      localStorage.setItem("paginationUsers", page.toString());
    } else {
      const newPage =
        1 + Math.floor((coursesPerPage / size) * (currentPage - 1));
      setCoursesPerPage(size);
      setCurrentPage(newPage);
      localStorage.setItem("paginationUsers", newPage.toString());
    }
  };

  const searchItems = (value: string) => {
    let newListUsers: IUser[] = [];
    if (userList) {
      for (let key of userList) {
        let { displayName } = key;
        let { email } = key;

        if (displayName && email) {
          displayName = displayName.toLowerCase();
          email = email.toLowerCase();
          if (displayName.includes(value.toLowerCase())) {
            newListUsers.push(key);
          } else if (email.includes(value.toLowerCase())) {
            newListUsers.push(key);
          }
        } else if (email) {
          email = email.toLowerCase();
          if (email.includes(value.toLowerCase())) {
            newListUsers.push(key);
          }
        } else if (displayName) {
          displayName = displayName.toLowerCase();
          if (displayName.includes(value.toLowerCase())) {
            newListUsers.push(key);
          }
        }
      }
      setArraySearch(newListUsers);
      localStorage.setItem("paginationUsers", "1");
      setCurrentPage(1);
    }
  };

  const sortBy = (tag: string) => {
    const arr = [...(arraySearch ?? [])];
    setArraySearch([]);

    if (tag === "Name") {
      if (stateFilterName === true)
        setArraySearch(
          arr?.sort(function (a, b) {
            if (a.displayName && b.displayName) {
              return a.displayName.toLowerCase() < b.displayName.toLowerCase()
                ? 1
                : -1;
            }
            if (!a.displayName) return -1;
            if (!b.displayName) return 1;
            return 0;
          })
        );
      else
        setArraySearch(
          arr?.sort(function (a, b) {
            if (a.displayName && b.displayName) {
              return b.displayName.toLowerCase() < a.displayName.toLowerCase()
                ? 1
                : -1;
            }
            if (!a.displayName) return 1;
            if (!b.displayName) return -1;
            return 0;
          })
        );

      setStateFilterName(!stateFilterName);
    }
    if (tag === "Email") {
      if (stateFilterEmail === false)
        setArraySearch(
          arr?.sort((a, b) =>
            a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1
          )
        );
      else
        setArraySearch(
          arr?.sort((a, b) =>
            a.email.toLowerCase() < b.email.toLowerCase() ? 1 : -1
          )
        );

      setStateFilterEmail(!stateFilterEmail);
    }
  };

  const changeSearchParam = (e : any) => {
    setSeletedUserTypes(e.target.value);
    setArraySearch(userList?.filter(userData => userData.roles.includes(e.target.value)))
    localStorage.setItem("paginationUsers", "1");
    setCurrentPage(1);
  }

  return (
    <>
      <div style={{ display: "flex", marginTop: "15px" }}>
        <span
          style={{
            alignSelf: "end",
            fontSize: "15px",
            fontWeight: "bolder",
            width: "200px",
          }}
        >
          Поиск пользователя:
        </span>
        <Input
          placeholder="Пример: someName@mail.ru"
          style={{ marginLeft: "20px" }}
          onChange={(item) => searchItems(item.target.value)}
        />
      </div>
      <Row className="courses-info-title" style={{ textAlign: "center", marginTop: "10px" }}>
        <Col
          span={12}
          style={{
            borderRight: "0.5px solid rgb(24, 144, 255)",
            backgroundColor: "white",
          }}
        >
          Email
          <Button
            style={{ border: "0px", marginRight: "auto" }}
            onClick={() => sortBy("Email")}
          >
            <SortAscendingOutlined />
          </Button>
        </Col>
        <Col
          span={12}
          style={{
            borderRight: "0.5px solid rgb(24, 144, 255)",
            backgroundColor: "white",
          }}
        >
          Роли
        </Col>
      </Row>
      <Row>
        <Radio.Group value={selectedUsersType} onChange={changeSearchParam}>
          <Radio className="selection-user-type-box" value={"Teacher"}>Выбрать только учителей</Radio>
          <Radio className="selection-user-type-box" value={"Admin"}>Выбрать только админов</Radio>
          <Radio className="selection-user-type-box" value={"User"}>Показать всех</Radio>
        </Radio.Group>
      </Row>
      {arrayForPagination &&
        arrayForPagination.map((item: IUser, index: number) => (
          <UserData key={item.authId} item={item} />
        ))}
      <Pagination
        onChange={handlePaginationChange}
        current={currentPage}
        total={arraySearch?.length}
        pageSize={coursesPerPage}
        showSizeChanger={false}
        hideOnSinglePage
        responsive
        size={"default"}
        style={{ alignSelf: "center", marginTop: 20 }}
      />
    </>
  );
};
