import React from "react";
import { loadStatsOfTestEvent } from "../../assets/requests";
import { Alert, Progress, Collapse, Image, Button } from "antd";
import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  MinusSquareOutlined,
} from "@ant-design/icons";
import { IStatsTest } from "../../assets/types/Types";

const { Panel } = Collapse;

interface IStatProp {
  testEventID: number;
}

const LoadStatOfTestEvent: React.FC<IStatProp> = ({ testEventID }) => {
  const [stats, setStats] = React.useState<IStatsTest[]>();
  const [showStats, setShowStats] = React.useState<IStatsTest[]>();
  const [panelShow, setPanelShow] = React.useState<
    number | number[] | string | string[]
  >("");
  const [textForButtomShowAll, setTextForButtomShowAll] =
    React.useState<string>("Развернуть все");
  const [textForButtomShowIncorrect, setTextForButtomShowIncorrect] =
    React.useState<string>("Развернуть неправильные");

  React.useEffect(() => {
    Promise.resolve(loadStatsOfTestEvent(testEventID)).then((result) =>
      setStats(result)
    );
  }, []);

  React.useEffect(() => {
    setShowStatsNew();
  }, [stats]);

  const setShowPanelFunc = (item: any) => {
    setPanelShow(item);
  };

  const setShowStatsNew = () => {
    const newStats: IStatsTest[] = [];
    if (stats)
      for (let key of stats) {
        newStats.push(key);
      }
    setShowStats(newStats);
  };

  const onClickShowAll = () => {
    setShowStatsNew();
    const newPanelShow: number[] = [];
    if (stats)
      for (let key in stats) {
        newPanelShow.push(+key);
      }
    if (textForButtomShowAll === "Развернуть все") {
      setTextForButtomShowAll("Свернуть все");
      setTextForButtomShowIncorrect("Развернуть неправильные");
      setShowPanelFunc(newPanelShow);
    } else {
      setTextForButtomShowAll("Развернуть все");
      setShowPanelFunc([]);
    }
  };

  const onClickShowIncorrect = () => {
    const newStats: IStatsTest[] = [];
    const newPanelShow: number[] = [];

    if (stats)
      for (let key of stats) {
        if (key.userAnswer && !key.isRight) newStats.push(key);
      }
    setShowStats(newStats);
    if (stats)
      for (let key in stats) {
        newPanelShow.push(+key);
      }
    if (textForButtomShowIncorrect === "Развернуть неправильные") {
      setTextForButtomShowIncorrect("Свернуть неправильные");
      setTextForButtomShowAll("Развернуть все");
      setShowPanelFunc(newPanelShow);
    } else {
      setTextForButtomShowIncorrect("Развернуть неправильные");
      setShowPanelFunc([]);
    }
  };

  return (
    <div className="container base-container">
      <h1 style={{ fontSize: "24px", fontWeight: "bold", margin: "15px 0px" }}>
        Результат теста
      </h1>
      {stats && (
        <Progress
          strokeColor="#52c41a"
          percent={Math.floor(
            (100 * stats?.filter((item) => item.isRight === true).length) /
              stats?.length
          )}
        />
      )}
      <div className="btns-view-statistic">
        <Button onClick={onClickShowAll}>{textForButtomShowAll}</Button>
        <Button onClick={onClickShowIncorrect} style={{ marginLeft: "20px" }}>
          {textForButtomShowIncorrect}
        </Button>
      </div>
      <Collapse
        onChange={(item) => {
          setShowPanelFunc(item);
        }}
        activeKey={panelShow}
        style={{ marginTop: "20px" }}
      >
        {stats &&
          showStats &&
          showStats?.map((item, index) => (
            <Panel
              header={"Вопрос №" + (index + 1)}
              forceRender={true}
              extra={
                item.isRight ? (
                  <CheckSquareOutlined
                    style={{ color: "#52c41a", fontSize: "20px" }}
                  />
                ) : !item.userAnswer ? (
                  <MinusSquareOutlined
                    style={{ color: "rgb(255 229 143)", fontSize: "20px" }}
                  />
                ) : (
                  <CloseSquareOutlined
                    style={{ color: "#f5222d", fontSize: "20px" }}
                  />
                )
              }
              key={index}
            >
              {item && item.questionData && item.image && item.userAnswer ? (
                <>
                  <div
                    style={{ marginBottom: "20px" }}
                    className="result-test-answer"
                  >
                    <span style={{ marginTop: "20px" }}>
                      {item.questionData}
                    </span>
                    <Image
                      src={`data:image/jpeg;base64,${item.image.blobData}`}
                    />
                  </div>
                  <span className="right-answer-span">Правильный ответ:</span>
                  <Alert
                    style={{ marginBottom: "20px", marginTop: "10px", backgroundColor: "#78cd22" }}
                    type="success"
                    message={item.rightAnswerData}
                  />
                  <span>Ваш ответ:</span>
                  <Alert
                    style={item.isRight ? { marginBottom: "20px", marginTop: "10px", backgroundColor: "#78cd22" } : { marginBottom: "20px", marginTop: "10px", backgroundColor: "#ff4225" }}
                    type={item.isRight ? "success" : "error"}
                    message={item.userAnswer}
                  />
                </>
              ) : item &&
                item.questionData &&
                item.userAnswer &&
                (!item.image ||
                  item.image.id === "00000000000000000000000000000000") ? (
                <>
                  <span
                    style={{
                      display: "inline-block",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    {" "}
                    {item.questionData}
                  </span>
                  <br />
                  <span className="right-answer-span">Правильный ответ:</span>
                  <Alert
                    style={{ marginBottom: "20px", marginTop: "10px", backgroundColor: "#78cd22" }}
                    type="success"
                    message={item.rightAnswerData}
                  />
                  <span>Ваш ответ:</span>
                  <Alert
                    style={item.isRight ? { marginBottom: "20px", marginTop: "10px", backgroundColor: "#78cd22" } : { marginBottom: "20px", marginTop: "10px", backgroundColor: "#ff4225" }}
                    type={item.isRight ? "success" : "error"}
                    message={item.userAnswer}
                  />
                </>
              ) : item &&
                !item.questionData &&
                item.image &&
                item.userAnswer ? (
                <>
                  <Image
                    style={{ marginBottom: "20px" }}
                    src={`data:image/jpeg;base64,${item.image.blobData}`}
                  ></Image>
                  <span className="right-answer-span">Правильный ответ:</span>
                  <Alert
                    style={{ marginBottom: "20px", marginTop: "10px", backgroundColor: "#78cd22" }}
                    type="success"
                    message={item.rightAnswerData}
                  />
                  <span>Ваш ответ:</span>
                  <Alert
                    style={item.isRight ? { marginBottom: "20px", marginTop: "10px", backgroundColor: "#78cd22" } : { marginBottom: "20px", marginTop: "10px", backgroundColor: "#ff4225" }}
                    type={item.isRight ? "success" : "error"}
                    message={item.userAnswer}
                  />
                </>
              ) : item &&
                item.questionData &&
                item.image &&
                item.image.id !== "00000000000000000000000000000000" &&
                !item.userAnswer ? (
                <>
                  <div className="result-test-answer">
                    <span style={{ marginTop: "20px" }}>
                      {" "}
                      {item.questionData}
                    </span>
                    <Image
                      src={`data:image/jpeg;base64,${item.image.blobData}`}
                    />
                  </div>
                  <span className="right-answer-span">Правильный ответ:</span>
                  <Alert
                    style={{ marginBottom: "20px", marginTop: "10px", backgroundColor: "#78cd22" }}
                    type="success"
                    message={item.rightAnswerData}
                  />
                  <br />
                  <span
                    style={{
                      marginTop: "20px",
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Вопрос пропущен
                  </span>
                </>
              ) : item &&
                item.questionData &&
                !item.userAnswer &&
                (!item.image ||
                  item.image.id === "00000000000000000000000000000000") ? (
                <>
                  <span
                    style={{
                      display: "inline-block",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    {" "}
                    {item.questionData}
                  </span>
                  <br />
                  <span className="right-answer-span">Правильный ответ:</span>
                  <Alert
                    style={{ marginBottom: "20px", marginTop: "10px", backgroundColor: "#78cd22" }}
                    type="success"
                    message={item.rightAnswerData}
                  />
                  <br />
                  <span
                    style={{
                      marginTop: "20px",
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Вопрос пропущен
                  </span>
                </>
              ) : (
                item &&
                !item.questionData &&
                item.image &&
                !item.userAnswer && (
                  <>
                    <Image
                      src={`data:image/jpeg;base64,${item.image.blobData}`}
                    ></Image>
                    <br />
                    <span className="right-answer-span">Правильный ответ:</span>
                    <Alert
                      style={{ marginBottom: "20px", marginTop: "10px", backgroundColor: "#78cd22" }}
                      type="success"
                      message={item.rightAnswerData}
                    />
                    <span
                      style={{
                        marginTop: "20px",
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      Вопрос пропущен
                    </span>
                  </>
                )
              )}
            </Panel>
          ))}
      </Collapse>
    </div>
  );
};

export default LoadStatOfTestEvent;
