import React, { ChangeEvent } from "react";
import FormAuthentication from "./FormAuthentication";
import { getAuthAction } from "../../../../store/actions/authenticate";
import { useDispatch } from "react-redux";
import { auth } from "../../../../assets/requests";
import {
  setIsFetchingLoading,
  setIsFetchingLoaded,
  setIsFetchingError,
} from "../../../../store/actions/isFetching";
// @ts-ignore
import { useAlert } from "react-alert";
import { Button } from "antd";

const Authorization: React.FC = () => {
  const [visibleForm, setVisibleForm] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();

  const dispatch = useDispatch();
  const alert = useAlert();

  const visibleHandler = (): void => setVisibleForm(true);
  const hideHandler = (): void => setVisibleForm(false);

  const passwordHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (password !== event.target.value) setPassword(event.target.value);
  };
  const emailHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (email !== event.target.value) setEmail(event.target.value);
  };

  const authHandler = async () => {
    if (email && password) {
      try {
        dispatch(setIsFetchingLoading());
        const result = await auth(email, password);
        localStorage.setItem("token", result?.data.bearerToken);
        localStorage.setItem("token-refresh", result?.data.refreshToken);
        dispatch(getAuthAction(result.data));
        dispatch(setIsFetchingLoaded());
      } catch {
        dispatch(setIsFetchingError());
        alert.error("Неправильный логин или пароль.");
      }
    }
  };

  return (
    <>
      <Button onClick={visibleHandler}>Войти</Button>
      {visibleForm && (
        <FormAuthentication
          visibleLogInForm={visibleForm}
          passwordHandler={passwordHandler}
          handleClose={hideHandler}
          userEmailHandler={emailHandler}
          authorizationHandler={authHandler}
        />
      )}
    </>
  );
};

export default Authorization;
