export interface INavBarItem {
  navBarItem: string;
}

export const SET_MENU_ITEM = "SET_MENU_ITEM";

type setMenuItem = {
  type: typeof SET_MENU_ITEM;
  payload: INavBarItem;
};

export type MenuItemAction = setMenuItem;

export const setMenuItem = (menuItem: INavBarItem): setMenuItem => {
  return {
    type: SET_MENU_ITEM,
    payload: menuItem,
  };
};
