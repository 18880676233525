import { combineReducers } from "redux";
import { authReducer } from "./authenticate";
import { IAuthResponse } from "../actions/authenticate";
import { IUserGeneralInfo } from "../actions/userInfo";
import { userInfoReducer } from "./userInfo";
import { ISectionState, sectionReducer } from "./seection";
import { currentCourseReducer, ICurrentCourse } from "./currentCourse";
import { isUserFetchingReducer } from "./isFetching";
import { StateFetching } from "../actions/isFetching";
import { navItemReducer } from "./navBarItem";
import { INavBarItem } from "../actions/navBarItem";

const rootReducer = combineReducers({
  auth: authReducer,
  userInfo: userInfoReducer,
  section: sectionReducer,
  currentCourse: currentCourseReducer,
  isUserFetching: isUserFetchingReducer,
  navItem: navItemReducer,
});

export interface RootState {
  auth: IAuthResponse;
  userInfo: IUserGeneralInfo;
  section: ISectionState;
  currentCourse: ICurrentCourse;
  isUserFetching: StateFetching;
  navItem: INavBarItem;
}

export default rootReducer;
