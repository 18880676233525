import {
  IAddFileInfo,
  IChangeFileInfo,
  ICourse,
  ICoursesWithLink,
  ICoursesWithLinkAndFiles,
  IDataImage,
  IdStatusLoadCourse,
  IFileInfoPdf,
  IIsLoadingCourse,
  IStateCheckAutoDelete,
} from "../types/Types";
import axios from "axios";
import $api from "./http";
import packageJson from "../../../package.json";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { IPagination } from "../../components/Pages/tests/TypesTest";
import {IAdminCourses} from "../../components/adminRoom/AdminCourse/AdminCoursesList";

export const loadCourseWithParts = async (id: string): Promise<any> => {
  const res = await $api
    .get(packageJson.server + `/getCoursePartsTree/${id}?format=json`)
    .then((res) => res.data);
  return res;
};

export const loadCoursesRange = async (
  currentPage: number,
  coursesPerPage: number,
  tag: string
): Promise<IPagination> => {
  return await axios
    .post<IPagination>(
      packageJson.server +
        `/courses/${currentPage}/${coursesPerPage}/${tag}?format=json`
    )
    .then((res) => res.data);
};

export const getAllCourses = async (): Promise<ICourse[]> => {
  return await $api
    .get(`${packageJson.server}/json/reply/getAllCourses?format=json`)
    .then((res) => res.data);
};

export const getCourseProgress = async (
  courseId: string,
  authId: string
): Promise<any> => {
  const response = await $api.post(
    packageJson.server + `/json/reply/getCourseProgressForUserInPersent`,
    { courseId, authId }
  );
  return response;
};

export const getAllTeamplateCourse = async (
  coursePartId: string
): Promise<any> => {
  const response = await axios.get(
    `${packageJson.server}/getTestTemplateByCoursePart/${coursePartId}?format=json`
  );
  return response;
};

export const getAllCoursesForAdimn = async (): Promise<
  ICoursesWithLink[] | undefined
> => {
  return await $api
    .get(`${packageJson.server}/getAllCourseWithLink`)
    .then((res) => res.data);
};

export const getAllAdminCourses = async (viewDeleted: boolean): Promise<
    IAdminCourses[] | undefined
> => {
  return await $api
      .get(`${packageJson.server}/GetAdminCourses?viewDeleted=${viewDeleted}`)
      .then((res) => res.data);
};

export const getCourseForEdit = async (
  Id: number
): Promise<ICoursesWithLinkAndFiles | undefined> => {
  return await $api
    .get(`${packageJson.server}/getCourseWithLinkByUniqId/${Id}`)
    .then((res) => res.data);
};

export const pingStatusAddCourse = async (
  idHash: string
): Promise<IIsLoadingCourse> => {
  return await $api
    .get(`${packageJson.server}/checkStatusUploadCourse/${idHash}`)
    .then((res) => res.data);
};

export const setNewDataCourse = async (
  Id: string,
  Note: string,
  LifeTime: number | string,
  Tags: string[] | CheckboxValueType[],
  Name: string,
  CourseIdHash: string,
  CreatedDate: any,
  UniqId: number,
): Promise<void> => {
  await $api.post(`${packageJson.server}/updateTemporaryCourse`, {
    Id,
    Note,
    LifeTime,
    Tags,
    Name,
    CourseIdHash,
    CreatedDate,
    UniqId,
  });
};

export const deleteCourseById = async (Id: string): Promise<void> => {
  await $api.post(`${packageJson.server}/courses/${Id}/delete`);
};

export const deleteCourseByHashId = async (Id: number): Promise<void> => {
  await $api.post(`${packageJson.server}/deleteCourseByHashId`, { Id });
};

export const addNewCourseTXT = async (
  textFile: { name: string; data: string; type: string },
  images: IDataImage[],
  lifeTime: number,
  createdDate: string,
): Promise<IdStatusLoadCourse> => {
  return await $api
    .post(
      `${packageJson.server}/uploadCourseTXT`,
      JSON.stringify({
        textFile: textFile,
        images: images,
        lifeTime: lifeTime,
        createdDate: createdDate,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.data);
};

export const addNewCoursePDF = async (
  questions: { name: string; data: string; type: string },
  answers: { name: string; data: string; type: string },
  lifeTime: number,
  createdDate: string,
): Promise<IdStatusLoadCourse> => {
  return await $api
    .post(
      `${packageJson.server}/uploadCoursePDF`,
      JSON.stringify({
        questions: questions,
        answers: answers,
        lifeTime: lifeTime,
        createdDate: createdDate,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.data);
};

export const addNewCourseSelect = async (
  id: string,
  lifetime: number,
  createdDate: string
): Promise<IdStatusLoadCourse> => {
  return await $api
    .post(
      `${packageJson.server}/createTemporaryCourse`,
      JSON.stringify({
        id: id,
        lifeTime: lifetime,
        createdDate: createdDate,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.data);
};

export const getAutoDeleteFlag = async (): Promise<IStateCheckAutoDelete> => {
  return await $api
    .get(`${packageJson.server}/getAutoDeleteFlag`)
    .then((res) => res.data);
};

export const startAutoDelete = async (): Promise<void> => {
  await $api
    .get(`${packageJson.server}/startAutoDelete`)
    .then((res) => res.data);
};

export const updateAutoDeleteFlag = async (
  stateAutoDelete: boolean
): Promise<void> => {
  await $api
    .post(`${packageJson.server}/updateAutoDeleteFlag`, { stateAutoDelete })
    .then((res) => res.data);
};

export const addFileInfoForCourse = async (
  file: IAddFileInfo
): Promise<void> => {
  await $api.post(`${packageJson.server}/json/reply/AddInfoToCourse`, file);
};

export const deleteFileInfoForCourse = async (
  fileId: string
): Promise<void> => {
  await $api.post(`${packageJson.server}/json/reply/DeleteInfoFromCourse`, {
    fileId,
  });
};

export const changeFileInfoForCourse = async (
  file: IChangeFileInfo
): Promise<void> => {
  await $api.post(`${packageJson.server}/json/reply/UpdateLearnInfoFile`, file);
};

export const getFileInfo = async (fileId: string): Promise<IFileInfoPdf> => {
  return await $api
    .get(`${packageJson.server}/getFileById/${fileId}`)
    .then((res) => res.data);
};
