import React from "react";
import {
  Button,
  Form,
  Input,
  Divider,
  Upload,
  Spin,
  Radio,
  Modal,
  DatePicker,
  Select,
} from "antd";
import {
  InboxOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  addNewCourseTXT,
  addNewCoursePDF,
  pingStatusAddCourse,
  getAllCourses,
  addNewCourseSelect,
} from "../../../assets/requests";
import { useHistory } from "react-router";
import moment, { Moment } from "moment";
import copy from "copy-to-clipboard";

const { Dragger } = Upload;
const { Option } = Select;

interface IData {
  name: string;
  data: string;
  type: string;
}

enum StatusLoadCourse {
  None = "Не загружается",
  Done = "Выполнено успешно",
  Loadnig = "Выполнение",
  Error = "Курс не создан",
}

const options = [
  { label: "TXT", value: "TXT" },
  { label: "PDF", value: "PDF" },
  { label: "Выбрать из существующих", value: "AnyCourse" },
];

interface ICourseForSelect {
  name: string;
  id: string;
}

const AddCourse: React.FC<any> = () => {
  const [defaultValue, setDefaultValue] = React.useState<string>("");
  const [statusLoad, setStatusLoad] = React.useState<string>("Не загружается");
  const [messageError, setMessageError] = React.useState<string>();
  const [idStatusLoad, setIdStatusLoad] = React.useState<string>();
  const [files, setFiles] = React.useState<any[] | undefined>([]);
  const [filePdfAnswers, setFilePdfAnswers] = React.useState<IData>();
  const [filePdfQuestion, setFilePdfQuestions] = React.useState<IData>();
  const [images, setImages] = React.useState<IData[]>([]);
  const [nameFile, setNameFile] = React.useState<string>("");
  const [dataFile, setDataFile] = React.useState<string>("");
  const [typeFile, setTypeFile] = React.useState<string>("");
  const [antPics, setAntPics] = React.useState();
  const [linkCourse, setLinkCourse] = React.useState<string>();
  const [disabledForAdd, setDisabledForAdd] = React.useState<boolean>(false);
  const [varianUpload, setVariantUpload] = React.useState<string>("TXT");
  const [lifeTime, setLifeTime] = React.useState<number | string
  >(14);
  const [selectCourse, setSelectCourse] = React.useState<ICourseForSelect[]>();
  const [selectedCourse, setSelectedCourse] =
    React.useState<ICourseForSelect>();

  const arr: any = [];

  const history = useHistory();

  React.useEffect(() => {
    Promise.resolve(getAllCourses()).then((res) => setSelectCourse(res));
  }, []);

  React.useEffect(() => {
    setDefaultValue(
      `data:image/jpeg;base64$, b6095d0c-3a7f-4802-a194-45af62b6281f`
    );
  }, [antPics]);

  React.useEffect(() => {
    if (files?.length) {
      createFilesForRequest(files);
    }
  }, [files]);

  React.useEffect(() => {
    if (idStatusLoad && varianUpload !== "AnyCourse") {
      let timerPing = setInterval(function () {
        if (
          statusLoad === StatusLoadCourse.None ||
          statusLoad === StatusLoadCourse.Loadnig
        ) {
          Promise.resolve(pingStatusAddCourse(idStatusLoad)).then((res) => {
            if (messageError && messageError !== "") {
              clearInterval(timerPing);
            }
            if (res.message) {
              setStatusLoad(StatusLoadCourse.Error);
              setMessageError("Не удалось обработать файл");
              clearInterval(timerPing);
            }
            if (res.status === StatusLoadCourse.Error) {
              setMessageError(res.message);
              setDisabledForAdd(false);
              setImages([]);
              setNameFile("");
              setDataFile("");
              setTypeFile("");
              setFiles(undefined);
              clearInterval(timerPing);
              setStatusLoad(res.status);
            }
            if (res.status === StatusLoadCourse.Done) {
              setMessageError("");
              setLinkCourse(res.link);
              setStatusLoad(res.status);
              clearInterval(timerPing);
            }
          });
        }
      }, 5000);
    } else if (idStatusLoad && varianUpload === "AnyCourse") {
      Promise.resolve(pingStatusAddCourse(idStatusLoad)).then((res) => {
        setMessageError("");
        setLinkCourse(res.link);
        setStatusLoad(StatusLoadCourse.Done);
      });
    }
  }, [idStatusLoad]);

  const onClickAddCouse = () => {
    if (
      !lifeTime
    ) {
      Modal.confirm({
        title: "Неверный срок действия курса",
        icon: <ExclamationCircleOutlined />,
        content: `Для добавления нужно ввести значение, не меньше чем 0`,
        okText: "Ок",
        cancelButtonProps: {
          style: {
            opacity: 0,
          },
        },
      });
    } else if (varianUpload === "TXT" && nameFile) {
      if (!dataFile) {
        Modal.confirm({
          title: "Файл пустой",
          icon: <ExclamationCircleOutlined />,
          content: `Проверьте содержание добавленного файла.`,
          okText: "Ок",
          cancelButtonProps: {
            style: {
              opacity: 0,
            },
          },
        });
      } else {
        //@ts-ignore
        Promise.resolve(
          addNewCourseTXT(
            { name: nameFile, data: dataFile, type: typeFile },
            images, //@ts-ignore
            lifeTime,
            new Date(moment().toString())
          )
        ).then((res) => {
          if (res.message) {
            setStatusLoad(StatusLoadCourse.Error);
            setMessageError(res.message);
            setDisabledForAdd(false);
            setImages([]);
            setNameFile("");
            setDataFile("");
            setTypeFile("");
            setFiles(undefined);
          } else {
            setStatusLoad(StatusLoadCourse.Loadnig);
            setIdStatusLoad(res.id);
            setMessageError("");
          }
        });
        setStatusLoad(StatusLoadCourse.Loadnig);
        setDisabledForAdd(true);
      }
    } else if (varianUpload === "PDF" && nameFile) {
      //@ts-ignore
      Promise.resolve(
        addNewCoursePDF(
          {
            name: nameFile, //@ts-ignore
            data: filePdfQuestion?.data, //@ts-ignore
            type: filePdfQuestion?.type,
          },
          {
            name: nameFile,
            data: filePdfAnswers?.data,
            type: filePdfAnswers?.type,
          }, //@ts-ignore
          lifeTime,
          new Date(moment().toString())
        )
      ).then((res) => {
        if (res.message) {
          setStatusLoad(StatusLoadCourse.Error);
          setMessageError(res.message);
          setDisabledForAdd(false);
          setImages([]);
          setNameFile("");
          setDataFile("");
          setTypeFile("");
          setFiles(undefined);
          setLifeTime(14)
        } else {
          setStatusLoad(StatusLoadCourse.Loadnig);
          setIdStatusLoad(res.id);
          setMessageError("");
        }
      });
      setStatusLoad(StatusLoadCourse.Loadnig);
      setDisabledForAdd(true);
    } else if (varianUpload === "AnyCourse") {
      Promise.resolve(
        //@ts-ignore
        addNewCourseSelect(selectedCourse, lifeTime, new Date(moment().toString()))
      ).then((res) => {
        if (res.message) {
          setStatusLoad(StatusLoadCourse.Error);
          setMessageError(res.message);
          setDisabledForAdd(false);
          setImages([]);
          setNameFile("");
          setDataFile("");
          setTypeFile("");
          setFiles(undefined);
        } else {
          setStatusLoad(StatusLoadCourse.Loadnig);
          setIdStatusLoad(res.id);
          setMessageError("");
        }
      });
      setStatusLoad(StatusLoadCourse.Loadnig);
      setDisabledForAdd(true);
    } else {
      setNameFile("");
      Modal.confirm({
        title: "Неверное имя файла",
        icon: <ExclamationCircleOutlined />,
        content: `Для добавления нужно ввести название`,
        okText: "Ок",
        cancelButtonProps: {
          style: {
            opacity: 0,
          },
        },
      });
    }
  };

  const handleAnt = (info: any) => {
    const { status } = info.file;
    if (status === "done") setFiles(info.fileList);
  };

  const handleSelectFileWithAnswers = (item: any) => {
    const { status } = item.file;
    if (status === "done") {
      const reader = new FileReader();
      reader.readAsDataURL(item.file.originFileObj);
      reader.onloadend = () => {
        if (reader.result)
          setFilePdfAnswers({
            name: nameFile,
            data: reader.result.toString().split(",")[1],
            type: reader.result.toString().split(",")[0],
          });
      };
    }
  };

  const handleSelectFileWithQuestions = (item: any) => {
    const { status } = item.file;

    if (status === "done") {
      const reader = new FileReader();
      reader.readAsDataURL(item.file.originFileObj);
      reader.onloadend = () => {
        if (reader.result) {
          setFilePdfQuestions({
            name: nameFile,
            data: reader.result.toString().split(",")[1],
            type: reader.result.toString().split(",")[0],
          });
        }
      };
    }
  };
  
  const disabledDate = (current : Moment) => {
    return current && current < moment().endOf('day');
  };

  const updateImages = () => setImages(arr);

  const createFilesForRequest = (files: Array<any>) => {
    if (files.length) {
      for (let item of files) {
        const { type } = item;

        let reader = new FileReader();
        reader.readAsDataURL(item.originFileObj);
        reader.onloadend = (e: any) => {
          if (reader.result && type === "text/plain") {
            setDataFile(reader.result.toString().split(",")[1]);
            setTypeFile(reader.result.toString().split(",")[0]);
          } else if (
            reader.result &&
            typeof type == "string" &&
            type.includes("image")
          ) {
            let arrayForImage: IData = {
              name: item.name,
              data: reader.result.toString().split(",")[1],
              type: reader.result.toString().split(",")[0],
            };

            arr.push(arrayForImage);
            updateImages();
          }
        };
      }
    }
  };

  const onClickCopyLink = () => {
    const linkCopy = linkCourse;

    if (linkCopy) copy(linkCopy);
  };

  const onChangeVariantFile = (e: any) => {
    setVariantUpload(e.target.value);
  };

  const changeNameFile = (item: any) => {
    setNameFile(item.trim());
  };

  const handleChangeLifeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value.replace(/\D/g, '');
    if (val)
    {
      setLifeTime(parseInt(val));
    }
    else {
      setLifeTime('');
    }
  };

  const onChangeSelectCoures = (item: any) => {
    setSelectedCourse(item);
  };

  return (
    <>
      <div className="container base-container">
        <Divider orientation="left">
          <span style={{ fontWeight: "bold" }}>Добавление курса</span>
        </Divider>
        <Form
          size="large"
          labelAlign="left"
          labelCol={{ span: 4 }}
          initialValues={{ remember: true }}
        >
          {varianUpload !== "AnyCourse" && (
            <Form.Item label="Название">
              <Input
                disabled={disabledForAdd}
                onChange={(item) => changeNameFile(item.target.value)}
              />
            </Form.Item>
          )}
          <Form.Item label="Срок действия ссылки:">
            <input
                type="text"
                placeholder="Кол. дней"
                value={lifeTime}
                onChange={(value) => handleChangeLifeTime(value)}
                disabled={disabledForAdd}
            />
          </Form.Item>
          <Form.Item label="Тип файла">
            <Radio.Group
              options={options}
              onChange={onChangeVariantFile}
              value={varianUpload}
              disabled={disabledForAdd}
              defaultValue={varianUpload}
              optionType="button"
            />
          </Form.Item>

          <Form.Item label="Загрузка файла">
            {varianUpload === "TXT" ? (
              <Dragger
                disabled={disabledForAdd}
                multiple
                action={defaultValue}
                onChange={handleAnt}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Нажмите или перетащите файлы для загрузки
                </p>
                <p className="ant-upload-hint">
                  Поддержка одиночной или множественной загрузки. Загружать TXT
                  файл или TXT файл и картики
                </p>
              </Dragger>
            ) : varianUpload === "PDF" ? (
              <div>
                <div className="PDF-upload-course-files" style={{ display: "flex", marginBottom: "20px" }}>
                  <span
                    style={{
                      alignSelf: "center",
                      marginRight: "15px",
                    }}
                  >
                    Файл с ответами
                  </span>
                  <Upload
                    disabled={disabledForAdd}
                    action={defaultValue}
                    listType={"text"}
                    className="upload-pdf-file"
                    maxCount={1}
                    onChange={handleSelectFileWithAnswers}
                  >
                    <Button
                      style={{ alignSelf: "center", marginRight: "15px" }}
                      size="middle"
                      disabled={disabledForAdd}
                      icon={<UploadOutlined />}
                    >
                      Выбрать
                    </Button>
                  </Upload>
                </div>
                <div className = "PDF-upload-course-files" style={{ display: "flex" }}>
                  <span
                    style={{
                      alignSelf: "center",
                      marginRight: "15px",
                    }}
                  >
                    Файл с вопросами
                  </span>
                  <Upload
                    disabled={disabledForAdd}
                    action={defaultValue}
                    listType={"text"}
                    className="upload-pdf-file"
                    maxCount={1}
                    onChange={handleSelectFileWithQuestions}
                  >
                    <Button
                      style={{ alignSelf: "center", marginRight: "15px" }}
                      size="middle"
                      disabled={disabledForAdd}
                      icon={<UploadOutlined />}
                      onChange={handleSelectFileWithAnswers}
                    >
                      Выбрать
                    </Button>
                  </Upload>
                </div>
              </div>
            ) : (
              <Select
                showSearch
                placeholder="Выбирите нужный курс"
                optionFilterProp="children"
                onChange={onChangeSelectCoures}
                filterOption={(input, option) =>
                  //@ts-ignore
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {selectCourse?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {messageError ? (
            <div>
              <Form.Item label="Статус">
                {statusLoad === StatusLoadCourse.Loadnig ? (
                  <div>
                    <span>{statusLoad}</span>
                    <Spin style={{ marginLeft: "15px" }} />
                  </div>
                ) : (
                  <span>{statusLoad}</span>
                )}
              </Form.Item>
              <Form.Item label="Ошибка">
                <span>{messageError}</span>
              </Form.Item>
            </div>
          ) : (
            <Form.Item label="Статус">
              {statusLoad === StatusLoadCourse.Loadnig ? (
                <div>
                  <span>{statusLoad}</span>
                  <Spin style={{ marginLeft: "15px" }} />
                </div>
              ) : (
                <span>{statusLoad}</span>
              )}
            </Form.Item>
          )}
          {statusLoad === StatusLoadCourse.Done && (
            <div className="text-link" style={{ display: "flex" }}>
              <Form.Item className="course-copy-url" label="Ссылка">
                <span>{linkCourse}</span>
                <Button
                  size="middle"
                  htmlType="button"
                  style={{ marginLeft: "15px" }}
                  onClick={() => {
                    onClickCopyLink();
                  }}
                >
                  Копировать ссылку
                </Button>
              </Form.Item>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={disabledForAdd}
              onClick={onClickAddCouse}
            >
              Добавить
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => history.push("/adminRoom")}
            >
              Закрыть
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddCourse;
