import React from "react";
import { Pagination, Row, Col, List } from "antd";
import { ICourse } from "../../../assets/types/Types";
import { IMenuItem } from "./TypesTest";
import { MenuItems } from "./MenuItems";
import { loadCoursesRange } from "../../../assets/requests";
import DownloadableElement, {
  DownloadStates,
} from "../../utils/DownloadableElement";
import TestList from "./TestList";

const Tests: React.FC = () => {
  const [selectedItem, setSelectedItem] = React.useState<number>(0);
  const [countCourses, setCountCourses] = React.useState<number>(0);
  const [courses, setCourses] = React.useState<ICourse[]>([]);
  const [coursesDownloadingState, setCoursesDownloadingState] =
    React.useState<DownloadStates>(DownloadStates.Undefined);
  const [coursesPerPage, setCoursesPerPage] = React.useState<number>(5);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  React.useEffect(() => {
    setCountCourses(0);
    setCoursesDownloadingState(DownloadStates.Downloading);
    fetchToBase();
  }, [selectedItem, currentPage, coursesPerPage]);

  const handleSelectedMenuItemChange = (index: number): void => {
    setSelectedItem(index);
    setCurrentPage(1);
    setCurrentPage(1);
  };

  const handlePaginationChange = (
    page: number,
    size: number | undefined
  ): void => {
    if (!size) return;
    if (size === coursesPerPage) {
      setCurrentPage(page);
    } else {
      //При изменении размера, переходим на другую страницу так, чтобы остался виден первый элемент сверху
      const newPage =
        1 + Math.floor((coursesPerPage / size) * (currentPage - 1));
      setCoursesPerPage(size);
      setCurrentPage(newPage);
    }
  };

  const fetchToBase = async () => {
    await loadCoursesRange(
      currentPage - 1,
      coursesPerPage,
      MenuItems[selectedItem].tag
    )
      .then((res) => {
        setCourses(res.courses);
        setCountCourses(res.countCourse);
        setCoursesDownloadingState(
          res.courses.length ? DownloadStates.OK : DownloadStates.Empty
        );
      })
      .catch(() => setCoursesDownloadingState(DownloadStates.Error));
  };

  return (
    <Row wrap={true} justify="center" className="test-container">
      <Col flex="1 1 100px" className="test-navigation">
        <div className="test-navigation">
          <List itemLayout="horizontal" className="test-menu">
            {MenuItems.map((item: IMenuItem, index: number) => (
              <List.Item
                key={index}
                className={
                  index === selectedItem ? "selected test-item" : "test-item"
                }
                onClick={() => handleSelectedMenuItemChange(index)}
              >
                <i>{item.ico}</i>
                <span>{item.name}</span>
              </List.Item>
            ))}
          </List>
        </div>
      </Col>
      <Col flex="auto" className="test-content">
        <DownloadableElement
          Сomponent={TestList}
          params={{
            courses: courses,
            defaultImageUrl: MenuItems[selectedItem].defaultImage,
          }}
          state={coursesDownloadingState}
        />
        <Pagination
          onChange={handlePaginationChange}
          current={currentPage}
          total={countCourses}
          pageSize={coursesPerPage}
          showSizeChanger={false}
          hideOnSinglePage
          responsive
          size={"default"}
          style={{ alignSelf: "center", marginTop: 20 }}
        />
      </Col>
    </Row>
  );
};

export default Tests;
