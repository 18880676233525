import React from "react";

import { Result } from "antd";

const ImpossibleElement: React.FC = () => {
  return (
    <Result
      icon={<span style={{ fontSize: 69 }}>🤔</span>}
      title="Странно..."
      subTitle="Этой надписи здесь быть не должно"
    />
  );
};

export default ImpossibleElement;
