import React from "react";
import { Steps, Typography, Button } from "antd";
import {
  FileTwoTone,
  DollarTwoTone,
  ContainerTwoTone,
  FileExcelTwoTone,
  IdcardTwoTone,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMenuItem } from "../../../store/actions/navBarItem";

const { Title } = Typography;
const { Step } = Steps;

const StepsStudying: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <>
      <Title
        style={{
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        Как проходит обучение
      </Title>
      <Steps direction="vertical">
        <Step
          icon={<FileTwoTone />}
          status={"finish"}
          title="Прохождение бесплатного теста"
          description="Выберите интересующее вас направление и пройдите бесплатный тест, для оценки уровня своей квалификации"
        />
        <Step
          icon={<DollarTwoTone />}
          title="Регистрация аккаунта, выбор курса и его оплата"
          description={`-`}
          status={"finish"}
        />
        <Step
          icon={<ContainerTwoTone />}
          title="Изучение материалов по выбранному курсу"
          description={`-`}
          status={"finish"}
        />
        <Step
          icon={<FileExcelTwoTone />}
          title="Прохождение теста"
          description={`-`}
          status={"finish"}
        />
        <Step
          icon={<IdcardTwoTone />}
          title="Получение cвидетельства по результатам"
          status={"finish"}
          description="В случае успешного прохождения теста, Вы получайте действительное свидетельство государственного образца о повышении квалификации, подтверждающее уровень вашей квалификации"
        />
      </Steps>
      <Button
        onClick={() => {
          localStorage.setItem("navLink", "testPage");
          dispatch(setMenuItem({ navBarItem: "testPage" }));
          history.push("/tests");
        }}
        style={{
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        Все курсы
      </Button>
    </>
  );
};

export default StepsStudying;
