import $api from "./http";
import packageJson from "../../../package.json";
import { IStatsTest } from "../types/Types";

export const closeTestEvent = async (testEvent: number): Promise<void> => {
  await $api
    .get(packageJson.server + `/closeTest/${testEvent}?format=json`)
    .then((res) => res.data);
};

export const startTestEvent = async (testEvent: number): Promise<void> => {
  await $api
    .get(packageJson.server + `/startTest/${testEvent}?format=json`)
    .then((res) => res.data);
};

export const loadStatsOfTestEvent = async (
  testEvent: number
): Promise<IStatsTest[]> => {
  return await $api
    .get<IStatsTest[]>(
      packageJson.server + `/loadStatsTestEvent/${testEvent}?format=json`
    )
    .then((res) => res.data);
};
