import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../store/reducers";
import { Button, Spin, Image, Result } from "antd";
import {
  closeTestEvent,
  createTestForUserCourse,
  getAllQuestionsForTestEvent,
  startTestEvent,
} from "../../assets/requests";
import {
  addQuestionEventForTest,
  getQuestionForTestEvent,
  registrationAnswerForQuestion,
} from "../../assets/requests";
import LoadStatOfTestEvent from "./LoadStatOfTestEvent";
import {
  clearCurrentTestAction,
  setCurrentTestAction,
} from "../../store/actions/currentCourse";
import StatusBarQuestion from "./statusBarQuestion/StatusBarQuestion";
import Answer from "./Answer";
import { IAnswer, IQuestion } from "../../assets/types/Types";
import { unstable_renderSubtreeIntoContainer } from "react-dom";

interface ITestEventProp {
  testTemplateId: string;
  isRandomAnswer: boolean;
  isRandomQuestion: boolean;
}

export interface IListQuestion {
  order: number;
  isRight: boolean | null;
  question: IQuestion;
}

const TestEvent: React.FC<ITestEventProp> = ({
  testTemplateId,
  isRandomAnswer,
  isRandomQuestion,
}) => {
  const auth = useSelector((state: RootState) => state.auth);
  const testEvent = useSelector((state: RootState) => state.currentCourse);
  const [question, setQuestion] = React.useState<IQuestion>();
  const [imgForQuestion, setImgForQuestion] = React.useState<
    string | undefined
  >();
  const [isAnswered, setIsAnswered] = React.useState<boolean>(false);
  const [ans, setAns] = React.useState<IAnswer | undefined>(undefined);
  const [visible, setVisible] = React.useState<boolean>(true);
  const [invisibleSkipQuestion, setInvisibleSkipQuestion] = React.useState<boolean>(false);
  const [list, setList] = React.useState<IListQuestion[]>();
  const [stateRemoved, setStateRemoved] = React.useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (auth) {
      Promise.resolve(
        createTestForUserCourse(auth.userId, testTemplateId)
      ).then((result) => {
        dispatch(setCurrentTestAction(result.testEventId));
        if (result && result.testEventId) {
          startTestEvent(result.testEventId);
          NextQuestionHandle(result.testEventId).then((res) => res);
          Promise.resolve(getAllQuestionsForTestEvent(result.testEventId)).then(
            (res: IQuestion[]) => {
              const ques = res.map((item) => {
                return {
                  order: item.serialNumber,
                  isRight: null,
                  question: item,
                };
              });
              setList(ques);
            }
          );
        }
      });
    }
    return () => {
      dispatch(clearCurrentTestAction());
    };
  }, []);

  const NextQuestionHandle = async (
    testEvent: number | null
  ): Promise<void> => {
    if (testEvent) {
      await getQuestionForTestEvent(isRandomQuestion, testEvent).then(
        (result) => {
          result.answers = deleteDuplicateAnswer(result.answers);
          result.answers = shuffleAnswers(result.answers);
          setQuestion(result);
        }
      );
      console.log();
      setAns(undefined);
      setIsAnswered(false);
      setVisible(true);
      setInvisibleSkipQuestion(false);
    }
  };

  const AnswerHandler = (answer: IAnswer) => {
    if (!isAnswered) {
      setIsAnswered(true);
      setAns(answer);

      if (list && answer) {
        let order = list?.find((item) => item.question.id === question?.id);
        if (order) {
          order.isRight = answer.isRight;
          setList([...list].sort((a, b) => a.order - b.order));
        }
      }
      if (testEvent.currentTestEvent && question?.id) {
        registrationAnswerForQuestion(
          question?.id,
          answer.isRight,
          testEvent.currentTestEvent,
          answer.id
        );
      }
      
      setVisible(false);
      setInvisibleSkipQuestion(true);
    }
  };

  const IsRight = (answer: IAnswer): boolean | null => {
    if (answer.isRight && answer !== ans && ans !== undefined) return true;
    if (answer === ans && ans.isRight) return true;
    if (answer === ans && !ans.isRight) return false;
    return null;
  };

  const SkipQuestionHandler = (): void => {
    if (testEvent.currentTestEvent && question?.id) {
      addQuestionEventForTest(
        null,
        false,
        question.id,
        testEvent.currentTestEvent
      );
      NextQuestionHandle(testEvent.currentTestEvent);
    }
  };

  const CloseTest = (): void => {
    if (testEvent.currentTestEvent) {
      closeTestEvent(testEvent.currentTestEvent);
      history.push(`/tests/endtTest/testEventID:${testEvent.currentTestEvent}`);
    }
  };

  const deleteDuplicateAnswer = (list: IAnswer[]): IAnswer[] => {
    const newList: IAnswer[] = [];

    for (let i = 0; i < list.length; i++) {
      if (
        newList.filter((item) => item.answerData === list[i].answerData)
          .length === 0
      ) {
        newList.push(list[i]);
      }
    }

    return newList;
  };

  const shuffleAnswers = (list: IAnswer[]) : IAnswer[] => {
    const listCopy: IAnswer[] = [];

    for (var a = list, i = a.length; i--; ) {
      var random = a.splice(Math.floor(Math.random() * (i + 1)), 1)[0];
      listCopy.push(random);
    }

    return listCopy;
  }

  return (
    <div className="container base-container">
      {question ? (
        <div>
          {list && <StatusBarQuestion list={list} currentQuestion={question} />}
          <br />
          {question &&
          question.questionData && question.image ? (
            <div className="test-data-view">
              <span className="text-question">{question.questionData}</span>
              <Image
                className="img-question"
                src={`data:image/png;base64,${question.image.blobData}`}
              />
            </div>
          ) : question && question.questionData && !question.image ? (
            <span>{question.questionData}</span>
          ) : question && !question.questionData && question.image && (
            <Image src={`data:image/jpeg;base64,${question.image.blobData}`} />
          )}
          <br />
          {question.answers.map((answer) => (
            <Answer
              key={answer.id}
              check={IsRight(answer)}
              selectedAnswer={() => AnswerHandler(answer)}
              data={answer.answerData}
            />
          ))}
          <div className="btns-test">
            <Button
              className="btn-next-question"
              disabled={visible}
              onClick=
              {() => NextQuestionHandle(testEvent.currentTestEvent)}
            >
              Следующий вопрос
            </Button>
            <Button
              onClick={SkipQuestionHandler}
              disabled={invisibleSkipQuestion}
            >
              Пропустить вопрос
              </Button>
            <Button
              className="btn-close-test"
              style={{ marginRight: "20px", marginLeft: "20px" }}
              onClick={() => {
                CloseTest();
              }}
            >
              Закончить тест
            </Button>
          </div>
        </div>
      ) : list &&
        list?.length > 0 &&
        list?.filter((item) => item.isRight === null).length === 0 &&
        testEvent.currentTestEvent ? (
        <LoadStatOfTestEvent testEventID={testEvent.currentTestEvent} />
      ) : (
        <Spin className="preloader" tip="Loading..." />
      )}
    </div>
  );
};

export default TestEvent;
