import React from "react";
import {
  getAllAdminCourses, updateAutoDeleteFlag,
} from "../../../assets/requests/courses";
import {Button, Row, Col, Pagination, Input, Switch} from "antd";
import {
  CheckOutlined, CloseOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { AdminCoursesListData } from "./AdminCourseListComponent/AdminCourseListData";

export interface IAdminCourses {
  id: string;
  name: string;
  isDeleted: boolean;
}

export const AdminCoursesListContent: React.FC<any> = () => {
  const [array, setArray] = React.useState<IAdminCourses[] | undefined>();
  const [stateCheckViewDeleted, setStateCheckViewDeleted] =
      React.useState<boolean>();
  const [arraySearch, setArraySearch] = React.useState<
    IAdminCourses[] | undefined
  >();
  const [coursesPerPage, setCoursesPerPage] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [arrayForPagination, setArrayForPagination] = React.useState<
    IAdminCourses[] | undefined
  >();
  const [stateFilterName, setStateFilterName] = React.useState<boolean>(false);
  const [stateFilterId, setStateFilterId] = React.useState<boolean>(false);
  const [stateFilterIsDeleted, setStateFilterIsDeleted] = React.useState<boolean>(false);

  React.useEffect(() => {
    Promise.resolve(getAllAdminCourses(stateCheckViewDeleted ?? false)).then((res) => setArray(res));
    const pagination = localStorage.getItem("paginationAdminCourses");
    if (!pagination) {
      localStorage.setItem("paginationAdminCourses", "1");
    }
  }, [stateCheckViewDeleted]);

  React.useEffect(() => {
    if (array) {
      setArraySearch(array);
    }
    const pagination = localStorage.getItem("paginationAdminCourses");
    if (pagination) {
      setCurrentPage(Number(pagination));
    }
  }, [array]);

  React.useEffect(() => {
    if (arraySearch) {
      const lastIndex = currentPage * coursesPerPage;
      const firstIndex = lastIndex - coursesPerPage;
      setArrayForPagination(arraySearch?.slice(firstIndex, lastIndex));
      const pagination = localStorage.getItem("paginationAdminCourses");
      if (pagination) {
        setCurrentPage(Number(pagination));
      }
    }
  }, [array, currentPage, arraySearch]);

  const handlePaginationChange = (
    page: number,
    size: number | undefined
  ): void => {
    if (!size) return;

    if (size === coursesPerPage) {
      setCurrentPage(page);
      localStorage.setItem("paginationAdminCourses", page.toString());
    } else {
      const newPage =
        1 + Math.floor((coursesPerPage / size) * (currentPage - 1));
      setCoursesPerPage(size);
      setCurrentPage(newPage);
      localStorage.setItem("paginationAdminCourses", newPage.toString());
    }
  };

  const searchItems = (value: string) => {
    let newListAdminCourse: IAdminCourses[] | undefined = [];
    if (array) {
      for (let key of array) {
        let { name } = key;

        name = name.toLowerCase();

        if (name) {
          name = name.toLowerCase();
          if (name.includes(value.toLowerCase())) {
            newListAdminCourse.push(key);
          }
        }
      }
      setArraySearch(newListAdminCourse);
      localStorage.setItem("paginationAdminCourses", "1");
      setCurrentPage(1);
    }
  };

  const sortBy = (tag: string) => {
    const arr = [...(arraySearch ?? [])];
    setArraySearch([]);
    if (tag === "Name" && arr) {
      if (stateFilterName === false)
        setArraySearch(
          arr?.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
        );
      else
        setArraySearch(
          arr?.sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
          )
        );

      setStateFilterName(!stateFilterName);
    }
    if (tag === "Id" && arr) {
      if (stateFilterId === false)
        setArraySearch(
            arr?.sort((a, b) =>
                a.id.toLowerCase() > b.id.toLowerCase() ? 1 : -1
            )
        );
      else
        setArraySearch(
            arr?.sort((a, b) =>
                a.id.toLowerCase() < b.id.toLowerCase() ? 1 : -1
            )
        );

      setStateFilterId(!stateFilterId);
    }
    if (tag === "IsDeleted" && arr) {
      if (stateFilterIsDeleted === false)
        setArraySearch(
            arr?.sort((a, b) =>
                a.isDeleted > b.isDeleted ? 1 : -1
            )
        );
      else
        setArraySearch(
            arr?.sort((a, b) =>
                a.isDeleted < b.isDeleted ? 1 : -1
            )
        );

      setStateFilterIsDeleted(!stateFilterIsDeleted);
    }
  };

  return (
    <>
      <div className="action-with-course">
        <div className="delete-all-course">
          <span>Показать удалнные курсы</span>
          <Switch
              className="is-to-delete-course"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={stateCheckViewDeleted}
              onChange={() => {setStateCheckViewDeleted(!stateCheckViewDeleted)}}
          />
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "15px" }}>
        <span
          style={{
            alignSelf: "end",
            fontSize: "15px",
            fontWeight: "bolder",
            width: "105px",
          }}
        >
          Поиск курса:
        </span>
        <Input
          placeholder="Пример: Курс водителей такси"
          style={{ marginLeft: "20px" }}
          onChange={(item) => searchItems(item.target.value.trim())}
          defaultValue={""}
        />
      </div>
      <p style={{ marginTop: "10px", color: "black", marginBottom: "0px" }}>
        Все курсы:
      </p>
      <Row className="courses-info-title" style={{ textAlign: "center" }}>
        <Col
          span={6}
          style={{
            borderLeft: "0.5px solid rgb(24, 144, 255)",
            borderRight: "0.5px solid rgb(24, 144, 255)",
            backgroundColor: "white",
          }}
        >
          Id
          <Button
              style={{ border: "0px", marginRight: "auto" }}
              onClick={() => sortBy("Id")}
          >
            <SortAscendingOutlined />
          </Button>
        </Col>
        <Col
          span={12}
          style={{
            borderRight: "0.5px solid rgb(24, 144, 255)",
            backgroundColor: "white",
          }}
        >
          Название
          <Button
            style={{ border: "0px", marginRight: "auto" }}
            onClick={() => sortBy("Name")}
          >
            <SortAscendingOutlined />
          </Button>
        </Col>
        <Col
          span={3}
          style={{
            borderRight: "0.5px solid rgb(24, 144, 255)",
            backgroundColor: "white",
          }}
        >
          Статус
          <Button
              style={{ border: "0px", marginRight: "auto" }}
              onClick={() => sortBy("IsDeleted")}
          >
            <SortAscendingOutlined />
          </Button>
        </Col>
      </Row>
      {arrayForPagination &&
        arrayForPagination.map((item: IAdminCourses, index) => (
          <AdminCoursesListData key={index} item={item} viewDeleted={stateCheckViewDeleted ?? false} />
        ))}
      <Pagination
        onChange={handlePaginationChange}
        current={currentPage}
        total={arraySearch?.length}
        pageSize={coursesPerPage}
        showSizeChanger={false}
        hideOnSinglePage
        responsive
        size={"default"}
        style={{ alignSelf: "center", marginTop: 20 }}
      />
    </>
  );
};
