import { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { getFileInfo } from "../../../assets/requests/courses";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface IPropPdf {
  id: string;
}

export const PdfViewerComponent: React.FC<IPropPdf> = ({ id }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState<string>();

  useEffect(() => {
    getFileInfo(id).then((res) => {
      // decode base64 string, remove space for IE compatibility
      let binary = atob(res.file.replace(/\s/g, ""));
      let len = binary.length;
      let buffer = new ArrayBuffer(len);
      let view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }

      // create the blob object with content-type "application/pdf"
      let blob = new Blob([view], { type: "application/pdf" });
      let url = URL.createObjectURL(blob);
      setFile(url);
    });
  }, []);

  //@ts-ignore
  function onDocumentLoadSuccess({ numPages }): any {
    setNumPages(numPages);
  }

  return (
    <div className="pdf-helper">
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page, index) => {
            return (
              <div key={index}>
                <div style={{ height: "10px" }} />
                <Page pageNumber={page} />
              </div>
            );
          })}
      </Document>
    </div>
  );
};
