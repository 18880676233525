import $api from "./http";
import packageJson from "../../../package.json";
import { IAnswer, ICheckUserAnswer, IQuestion } from "../types/Types";

export const registrationUserAnswer = async (
  TestEventId: number,
  UserAnswer: string,
  AnswerTime: string,
  QuestionId: string
): Promise<ICheckUserAnswer> => {
  return await $api
    .get<ICheckUserAnswer>(
      packageJson.server +
        `/reganswer/${TestEventId}/${UserAnswer}/${AnswerTime}/${QuestionId}?format=json`
    )
    .then((res) => res.data);
};

export const createAnswerForQuestionEvent = async (
  questionId: string,
  answerData: string,
  isRight: boolean
): Promise<IAnswer> => {
  return await $api
    .post<IAnswer>(packageJson.server + `/createanswerbyquestion`, {
      questionId,
      answerData,
      isRight,
    })
    .then((res) => res.data);
};

export const addQuestionEventForTest = async (
  answerUserId: string | null,
  isRight: boolean,
  questionId: string,
  testEvent: number
): Promise<void> => {
  let url: string = packageJson.server + `/setquestionevent`;

  await $api
    .post(url, { answerUserId, isRight, questionId, testEvent })
    .then((res) => res.data);
};

export const registrationAnswerForQuestion = async (
  questionId: string,
  isRight: boolean,
  testEvent: number,
  answerId: string
): Promise<void> => {
  await addQuestionEventForTest(answerId, isRight, questionId, testEvent);
};

export const getQuestionForTestEvent = async (
  isRandom: boolean,
  testEvent: number
): Promise<IQuestion> => {
  let url: string = "";

  if (isRandom)
    url =
      packageJson.server +
      `/getQuestionForTest/${testEvent}/${isRandom}?format=json`;
  else
    url = packageJson.server + `/getQuestionForTest/${testEvent}?format=json`;

  const response = await $api.get<IQuestion>(url).then((res) => res.data);
  return response;
};

export const getAllQuestionsForTestEvent = async (
  testEvent: number
): Promise<IQuestion[]> => {
  return await $api
    .get<IQuestion[]>(
      packageJson.server + `/getAllTestQuestion/${testEvent}?format=json`
    )
    .then((res) => res.data);
};
