import * as React from "react";
import { IFileInfo, ITestTemplate } from "../../../assets/types/Types";
import { Typography, Button, List, Progress } from "antd";
import { useHistory } from "react-router-dom";
import { getCourseProgress } from "../../../assets/requests";
import { RootState } from "../../../store/reducers";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllTeamplateCourse } from "../../../assets/requests";

const { Title } = Typography;

interface TestProp {
  item: ITestTemplate;
  files: IFileInfo[];
}

const Test: React.FC<TestProp> = ({ item, files }) => {
  const history = useHistory();

  const auth = useSelector((state: RootState) => state.auth);
  const course = useSelector(
    (state: RootState) => state.currentCourse.currentCourse?.id
  );

  const [tests, setTests] = React.useState<ITestTemplate[]>();
  const [percent, setPercent] = React.useState<number | undefined>();
  const [listTest, setListTest] = React.useState<ITestTemplate[]>();

  useEffect(() => {
    //@ts-ignore
    Promise.resolve(getCourseProgress(course, auth.userId)).then((res) =>
      setPercent(res.data.result)
    );
    Promise.resolve(getAllTeamplateCourse(item.id)).then((res) =>
      setTests(res.data)
    );
  }, [item]);

  useEffect(() => {
    if (tests) {
      setListTest([...tests]);
    }
  }, [tests]);

  return (
    <div>
      <Title className="h4-info-course" level={4}>
        {item.name}
      </Title>
      <Progress
        percent={percent}
        strokeColor={{
          "0%": "#108ee9",
          "100%": "#87d068",
        }}
      />
      <div style={{ display: "flex", margin: "10px 10px 10px 0" }}>
        {files &&
          files.map((item) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0 10px 10px 0",
              }}
            >
              <Title className="h4-info-course" level={4}>
                {item.categoryName}
              </Title>
              <Button
                onClick={() => history.push(`/helpInfo/id:${item.fileId}`)}
              >
                Просмотреть
              </Button>
            </div>
          ))}
      </div>
      <List
        className="h3-info-course"
        header={<h3 className="list-header-tests">Название теста</h3>}
      >
        {listTest &&
          listTest?.map((item, index) => (
            <>
              <List.Item key={index + item.coursePartId}>{item.name}</List.Item>
              <Button
                onClick={() =>
                  history.push(
                    `/test/started/id:${item.id}/rA:${item.commonTestSettings.isRandomAnswers}/rQ:${item.commonTestSettings.isRandomQuestions}`
                  )
                }
                className="btn-test-start"
              >
                Пройти тест
              </Button>
            </>
          ))}
      </List>
    </div>
  );
};

export default Test;
