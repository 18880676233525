import {
  MenuItemAction,
  SET_MENU_ITEM,
  INavBarItem,
} from "../actions/navBarItem";

const navLinkName = localStorage.getItem("navLink");

const initialState: INavBarItem = { navBarItem: navLinkName ?? " " };

export const navItemReducer = (
  state: INavBarItem | null = initialState,
  action: MenuItemAction
) => {
  switch (action.type) {
    case SET_MENU_ITEM:
      return {
        navBarItem: action.payload.navBarItem,
      };
    default:
      return state;
  }
};
