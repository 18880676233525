import axios from "axios";
import packageJson from "../../../package.json";
import { IAuthResponse } from "../../store/actions/authenticate";
import $api from "./http";

export const checkAuth = async () => {
  try {
    const response = await $api.post<IAuthResponse>(
      `${packageJson.server}/auth?format=json`
    );
    response.data.roles = await axios
      .get(
        `${packageJson.server}/getUserById/${response.data.userId}?format=json`
      )
      .then((res) => res.data.roles);
    response.data.userName = await axios
      .get(
        `${packageJson.server}/getUserById/${response.data.userId}?format=json`
      )
      .then((res) => res.data.email);
    return response.data;
  } catch {
    const getNewAccessToken = await $api.post(
      `${packageJson.server}/access-token`,
      { refreshToken: localStorage.getItem("token-refresh") }
    );

    localStorage.setItem("token", getNewAccessToken.data.accessToken);

    const response = await $api.post<IAuthResponse>(
      `${packageJson.server}/auth?format=json`
    );
    response.data.userName = await axios
      .get(
        `${packageJson.server}/getUserById/${response.data.userId}?format=json`
      )
      .then((res) => res.data.email);
    response.data.roles = await axios
      .get(
        `${packageJson.server}/getUserById/${response.data.userId}?format=json`
      )
      .then((res) => res.data.roles);

    return response.data;
  }
};
