import React from "react";
import AuthModal from "./authorization/AuthModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";
import Authorization from "./authorization/Authorization";
import Registration from "./registration/Registration";

const AccountButtonsBlock: React.FC = () => {
  let auth = useSelector((state: RootState) => state.auth);
  let userInfo = useSelector((state: RootState) => state.userInfo);

  return (
    <>
      {auth === null ? (
        <>
          <Registration />
          <Authorization />
        </>
      ) : (
        <>
          {userInfo && userInfo?.userInfo && userInfo?.userInfo?.name ? (
            <AuthModal name={userInfo?.userInfo?.email} roles={auth.roles} />
          ) : (
            <AuthModal name={auth.userName} roles={auth.roles} />
          )}
        </>
      )}
    </>
  );
};

export default AccountButtonsBlock;
