import React from "react";
import YandexMap from "./YandexMap";
import { Typography, Row, Col, Space } from "antd";
const { Title, Text } = Typography;

const styleInfoRow = {
  marginTop: 25,
  marginBottom: 50,
};

const styleTextInfoColumn = {
  marginBottom: 20,
  fontSize: 18,
};

const styleMapColumn = {
  width: "min(100%, 500px)",
  height: "min(100vw, 500px)",
  minWidth: "min(100%, 500px)",
  minHeight: "min(100vw, 500px)",
  alignSelf: "center",
};

const Contacts: React.FC = () => {
  return (
    <div className="container base-container">
      <Row wrap={true} style={styleInfoRow}>
        <Col flex="1 1 50%" style={styleTextInfoColumn}>
          <Space direction="vertical">
            <Title level={2} style={{ fontWeight: "normal" }}>
              Центр повышения квалификации руководящих работников и специалистов
              «ПромТехОбразование»
            </Title>

            <Title level={4}>
              Юридический адрес:{" "}
              <Text>
                220012, ул. Чернышевского, д. 10, кабинет 75, г. Минск,
                Республика Беларусь<br></br>УНП 192278178, ОКПО 381770375000
              </Text>
            </Title>

            <Title level={4}>
              Банковские реквизиты:{" "}
              <Text>
                р/с BY20SOMA30150227230101000933 в ЗАО «Идея Банк», код банка
                (БИК/BIC) <Text strong>SOMABY22</Text> Республика Беларусь,
                220034 г. Минск, ул. З.Бядули, 11
              </Text>
            </Title>

            <Title level={4}>
              Для связи:
              <ul>
                <li>Факс: 8 (017) 290-49-98</li>
                <li>Тел: 8 (017) 397-60-25</li>
                <li>Моб: 8 (029) 686-60-25 — A1</li>
                <li>Моб: 8 (033) 686-60-25 — МТС</li>
              </ul>
            </Title>

            <Title level={4}>
              Email: <Text>info@pto.by</Text>
            </Title>

            <Title level={4}>
              Часы работы: <Text>Пн-Пт с 8:30 до 17:30</Text>{" "}
            </Title>
          </Space>
        </Col>
        <Col flex="0 0 50px" xs={1}></Col>
        <Col flex="1 1" style={styleMapColumn}>
          <YandexMap
            center_x={53.927703}
            center_y={27.599776}
            mark_x={53.927703}
            mark_y={27.599776}
          />
        </Col>
      </Row>

      <Row wrap={true} style={styleInfoRow}>
        <Col flex="1 1 50%" style={styleTextInfoColumn}>
          <Space direction="vertical">
            <Title level={2} style={{ fontWeight: "normal" }}>
              Учебно-консультационный пункт
            </Title>

            <Title level={4}>
              Юридический адрес:{" "}
              <Text>
                213826, ул. К. Либкнехта, д. 77 (вход со стороны городского
                парка), г. Бобруйск, Республика Беларусь
              </Text>
            </Title>

            <Title level={4}>
              Для связи:
              <ul>
                <li>Факс: 8 (0225) 72-50-12</li>
                <li>Моб: 8 (029) 196-60-25 — A1</li>
                <li>Моб: 8 (033) 626-60-25 — МТС</li>
              </ul>
            </Title>

            <Title level={4}>
              Email: <Text>info@pto.by</Text>
            </Title>

            <Title level={4}>
              Часы работы: <Text>Пн-Пт с 8:30 до 17:30</Text>{" "}
            </Title>
          </Space>
        </Col>
        <Col flex="0 0 50px" xs={1}></Col>
        <Col flex="1 1" style={styleMapColumn}>
          <YandexMap
            center_x={53.137279}
            center_y={29.217866}
            mark_x={53.137279}
            mark_y={29.217866}
          />
        </Col>
      </Row>
    </div>
  );
};
export default Contacts;
