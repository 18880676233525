import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { clearUserInfo } from "./userInfo";
export interface IAuthResponse {
  refreshToken: string;
  bearerToken: string;
  userId: string;
  sessionId: string;
  userName: string;
  displayName: string;
  roles: string[];
}

export const SET_AUTH_INFO = "SET_AUTH_INFO";
export const CLEAR_AUTH_INFO = "CLEAR_AUTH_INFO";

type setAuth = {
  type: typeof SET_AUTH_INFO;
  payload: IAuthResponse | null;
};

type clearAuth = {
  type: typeof CLEAR_AUTH_INFO;
  payload: IAuthResponse | null;
};

export type AuthActions = setAuth | clearAuth;

export const getAuthAction = (auth: IAuthResponse): setAuth => {
  return {
    type: SET_AUTH_INFO,
    payload: auth,
  };
};

export const clearAuthAction = (): clearAuth => {
  localStorage.removeItem("token");
  localStorage.removeItem("token-refresh");
  return {
    type: CLEAR_AUTH_INFO,
    payload: null,
  };
};

//@ts-ignore
export const clearUserData: ActionCreator<ThunkAction<Promise<any>, IAuthResponse, any, IAuthResponse>> = () => {
  return async (dispatch: Dispatch) => {
    dispatch(clearAuthAction());
    dispatch(clearUserInfo());
  };
};
