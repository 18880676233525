import React, { ChangeEvent } from "react";
import { Modal, Input, Typography, Form } from "antd";
const { Title } = Typography;

interface FormAuthenticationProps {
  visibleLogInForm: boolean;
  handleClose: () => void;
  userEmailHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  passwordHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  authorizationHandler: () => void;
}

const FormAuthentication: React.FC<FormAuthenticationProps> = ({
  visibleLogInForm,
  passwordHandler,
  handleClose,
  userEmailHandler,
  authorizationHandler,
}) => {
  return (
    <Modal
      visible={visibleLogInForm}
      title="Вход"
      onCancel={handleClose}
      onOk={authorizationHandler}
      cancelText="Отмена"
      okText="Войти"
    >
      <Form size="large" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <Form.Item
          label={
            <Title level={5} style={{ fontWeight: "normal", marginTop: 5 }}>
              Почта
            </Title>
          }
        >
          <Input
            placeholder="example@mail.com"
            id="email"
            onChange={(event) => userEmailHandler(event)}
          />
        </Form.Item>
        <Form.Item
          label={
            <Title level={5} style={{ fontWeight: "normal", marginTop: 5 }}>
              Пароль
            </Title>
          }
        >
          <Input.Password
            id="password"
            onChange={(event) => passwordHandler(event)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormAuthentication;
