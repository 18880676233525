import React from "react";
import { NavLink } from "react-router-dom";
import { BackTop } from "antd";
import { Menu, Typography, Row, Col } from "antd";
import AccountButtonsBlock from "./auth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { setMenuItem } from "../../store/actions/navBarItem";
import { MenuOutlined } from "@ant-design/icons";

const { Title } = Typography;

const Header: React.FC = () => {
  const [current, setCurrent] = React.useState<string>("mainPage");
  const handleClick = (e: { key: React.SetStateAction<string> }) =>
    setCurrent(e.key);
  const dispatch = useDispatch();
  let navItem = useSelector((state: RootState) => state);

  React.useEffect(() => {
    setCurrent(navItem.navItem.navBarItem);
  }, [navItem]);
  return (
    <>
      <Row style={{ backgroundColor: "white", width: "100%" }} wrap={false}>
        <Col className="pto-paddings">
          <Title level={3}>
            <a
              href="https://sdo.pto.by/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ПромТехОбразование
            </a>
          </Title>
        </Col>
        <Col flex="auto" className="menu-paddings">
          <Menu
            className="menu-box"
            onClick={handleClick}
            selectedKeys={[current]}
            mode="horizontal"
            overflowedIndicator={<MenuOutlined className="menu-outlined"/>}
          >
            <Menu.Item key={"mainPage"}>
              <NavLink
                to={"/"}
                onClick={() => {
                  localStorage.setItem("navLink", "mainPage");
                  dispatch(setMenuItem({ navBarItem: "mainPage" }));
                }}
              >
                Главная
              </NavLink>
            </Menu.Item>
            <Menu.Item key={"testPage"}>
              <NavLink
                to={"/tests"}
                onClick={() => {
                  localStorage.setItem("navLink", "testPage");
                  dispatch(setMenuItem({ navBarItem: "testPage" }));
                }}
              >
                Тесты
              </NavLink>
            </Menu.Item>
            {/* <Menu.Item key={ "pricePage" }>
								<NavLink to={ "/price" }>Цены</NavLink>
							</Menu.Item> */}
            <Menu.Item key={"aboutPage"}>
              <NavLink
                to={"/about"}
                onClick={() => {
                  localStorage.setItem("navLink", "aboutPage");
                  dispatch(setMenuItem({ navBarItem: "aboutPage" }));
                }}
              >
                О проекте
              </NavLink>
            </Menu.Item>
            <Menu.Item key={"contactsPage"}>
              <NavLink
                to={"/contacts"}
                onClick={() => {
                  localStorage.setItem("navLink", "contactsPage");
                  dispatch(setMenuItem({ navBarItem: "contactsPage" }));
                }}
              >
                Контакты
              </NavLink>
            </Menu.Item>
            <Menu.Item
              disabled
              className="accountant-btn"
              style={{ marginLeft: "auto", marginRight: 0, cursor: "default", display:"flex" }}
              key={"buttons"}
            >
              <AccountButtonsBlock />
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <BackTop className="back-top" />
    </>
  );
};
export default Header;
