import React from "react";

import { Typography, List, Descriptions, Col } from "antd";
import "antd/dist/antd.css";

const { Title } = Typography;

const styleZeroPadding = {
  padding: 0,
};

const styleIconPadding = {
  paddingRight: 10,
};

const styleItemTopPadding = {
  marginTop: 10,
};

const styleLinkToAction = {
  color: "#1890FF",
};

interface OfficeDescriptionProp {
  city: string;
  adress: string;
  phones: string[];
  mail: string;
  worktime: string;
}

const OfficeDescription: React.FC<OfficeDescriptionProp> = ({
  city,
  adress,
  phones,
  mail,
  worktime,
}) => {
  return (
    <Col
      flex="1 1"
      style={{
        minWidth: 200,
        marginRight: 50,
      }}
    >
      <Descriptions
        layout="vertical"
        column={1}
        contentStyle={{ fontSize: "18px" }}
        style={{ paddingBottom: "25px" }}
      >
        <Descriptions.Item
          style={styleZeroPadding}
          contentStyle={styleItemTopPadding}
        >
          <Title level={3}>{city}</Title>
        </Descriptions.Item>
        <Descriptions.Item style={styleZeroPadding}>
          <i className="fas fa-map-marked-alt" style={styleIconPadding} />
          {adress}
        </Descriptions.Item>
        <Descriptions.Item
          style={styleZeroPadding}
          contentStyle={styleItemTopPadding}
        >
          <List split={false} style={{ fontSize: "16 px" }}>
            {phones.map((phone) => (
              <List.Item
                key={phone}
                style={{ ...styleZeroPadding, marginLeft: "5px" }}
              >
                <i className="fas fa-phone-alt" style={styleIconPadding} />
                <a href={`tel:${phone}`} style={styleLinkToAction}>
                  {phone}
                </a>
              </List.Item>
            ))}
          </List>
        </Descriptions.Item>
        <Descriptions.Item
          style={styleZeroPadding}
          contentStyle={styleItemTopPadding}
        >
          <i className="fas fa-envelope" style={styleIconPadding} />
          <a href={`mailto:${mail}`} style={styleLinkToAction}>
            {mail}
          </a>
        </Descriptions.Item>
        <Descriptions.Item
          style={styleZeroPadding}
          contentStyle={styleItemTopPadding}
        >
          <i className="fas fa-clock" style={styleIconPadding} />
          {worktime}
        </Descriptions.Item>
      </Descriptions>
    </Col>
  );
};

export default OfficeDescription;
