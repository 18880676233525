import React from "react";
import { useState } from "react";
import {
  FullscreenControl,
  GeolocationControl,
  Map,
  Placemark,
  YMaps,
  ZoomControl,
} from "react-yandex-maps";

export interface YandexMapProps {
  center_x: number;
  center_y: number;
  mark_x: number;
  mark_y: number;
}

const YandexMap: React.FC<YandexMapProps> = ({
  center_x,
  center_y,
  mark_x,
  mark_y,
}) => {
  const [Rendering, setRendering] = useState<boolean>(false);

  setTimeout(() => setRendering(true));

  return (
    <>
      {Rendering && (
        <YMaps height={"100%"} width={"100%"}>
          <Map
            height={"100%"}
            width={"100%"}
            defaultState={{
              center: [center_x, center_y],
              zoom: 15,
            }}
          >
            <Placemark geometry={[mark_x, mark_y]} />
            <FullscreenControl />
            <GeolocationControl options={{ float: "left" }} />
            <ZoomControl options={{ float: "right" }} />
          </Map>
        </YMaps>
      )}
    </>
  );
};

export default YandexMap;
