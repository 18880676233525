import React from "react";
import { ICourse } from "../../../assets/types/Types";
import { Row, Col } from "antd";
import TestItem from "./TestItem";

const TestList: React.FC<{ params: { courses: ICourse[] } }> = ({ params }) => {
  return (
    <Row className="cards-test" wrap={true} gutter={20} justify="start">
      {params.courses.map((item: ICourse, index) => (
        <Col
          key={index}
          flex="1 1 300px"
          style={{ minWidth: 300, marginBottom: "50px" }}
        >
          <TestItem item={item} />
        </Col>
      ))}
    </Row>
  );
};

export default TestList;
