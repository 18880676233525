import React from "react";
import { GetFullStatForCourse } from "../../assets/requests";
import { Tree, Progress } from "antd";
import { IPartStat, ITreeStatistic, StatProp } from "../../assets/types/Types";

const StatisticsForCourse: React.FC<StatProp> = ({ courseId, userID }) => {
  const [stat, setStat] = React.useState<IPartStat[]>();

  const gData: ITreeStatistic[] = [];

  React.useEffect(() => {
    Promise.resolve(GetFullStatForCourse(courseId, userID)).then((res) =>
      setStat(res)
    );
  }, []);

  const generateNameTest = () => {
    if (stat) {
      let filteredData = stat.filter(
        (item) => item.namePart !== "Структура курса"
      );

      filteredData.sort((a, b) => {
        if (a.namePart > b.namePart) return 1;
        return -1;
      });

      for (let i = 0; i < filteredData?.length; i++) {
        gData.push({
          title: filteredData[i].namePart,
          key: `name ${i}`,
          children: [],
        });
        for (let j = 0; j < filteredData[i].eventStat.length; j++) {
          if (
            gData[i].children !== undefined &&
            filteredData[i].eventStat[j].stat.length > 0
          ) {
            gData[i].children.push({
              title: filteredData[i].eventStat[j]?.nameTest,
              key: `name ${i}-${j}`,
              children: [],
            });

            for (let k = 0; k < filteredData[i].eventStat[0].stat.length; k++) {
              gData[i].children[j].children?.push({
                title: (
                  <div className="test-progress">
                    <span>Попытка {k + 1} - </span>
                    <Progress
                      trailColor="red"
                      strokeColor="green"
                      percent={filteredData[i].eventStat[j]?.stat[k].result}
                    />
                  </div>
                ),
                key: `name ${i}-${j}-${k}`,
                children: [],
              });
            }
          }
        }
      }
    }
    return gData;
  };

  return (
    <div className="container base-container">
      <h1>Ваши тесты</h1>
      <Tree defaultExpandedKeys={["0-0-0"]} treeData={generateNameTest()} />
    </div>
  );
};

export default StatisticsForCourse;
