import packageJson from "../../../package.json";
import { IResPassword } from "../types/Types";
import $api from "./http";

export const ChangeOldPassword = async (
  Email: string,
  OldPassword: string,
  NewPassword: string
): Promise<IResPassword> => {
  let response = await $api
    .post<IResPassword>(packageJson.server + `/editPassword?format=json`, {
      Email,
      OldPassword,
      NewPassword,
    })
    .then((res) => res.data);
  return response;
};
