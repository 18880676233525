import React, { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import moment, { Moment } from "moment";
// @ts-ignore
import { useAlert } from "react-alert";
import { loadOrganization, saveEditData } from "../../assets/requests";
import { Form, Input, Button } from "antd";
import { SelectValue } from "antd/lib/select";
import { useHistory } from "react-router-dom";
import { IOrganization } from "../../assets/types/Types";

const EditProfile: React.FC = () => {
  let userInfo = useSelector((state: RootState) => state.userInfo);
  let auth = useSelector((state: RootState) => state.auth);

  const alert = useAlert();
  const history = useHistory();

  type SizeType = Parameters<typeof Form>[0]["size"];

  const [organization, setOrganization] = React.useState<IOrganization[]>();
  const [firstName, setFirstName] = React.useState<string | undefined>("");
  const [name, setName] = React.useState<string | undefined>("");
  const [lastName, setLastName] = React.useState<string | undefined>("");
  const [gender, setGender] = React.useState<string | undefined>("");
  const [birthDate, setBirthDate] = React.useState<number | string | undefined>(
    0
  );
  const [email, setEmail] = React.useState<string | undefined>("");
  const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>("");
  const [address, setAddress] = React.useState<string | undefined>("");
  const [serialAndNumber, setSerialAndNumber] = React.useState<
    string | undefined
  >("");
  const [dateIssue, setDateIssue] = React.useState<number | string | undefined>(
    0
  );
  const [org, setOrg] = React.useState<string | undefined>("");
  const [number, setNumber] = React.useState<string | undefined>("");
  const [componentSize, setComponentSize] = React.useState<SizeType | "middle">(
    "middle"
  );

  React.useEffect(() => {
    if (userInfo) {
      userInfo.userInfo?.firstName &&
        setFirstName(userInfo.userInfo?.firstName);
      userInfo.userInfo?.name && setName(userInfo.userInfo?.name);
      userInfo.userInfo?.fathersName &&
        setLastName(userInfo.userInfo?.fathersName);
      userInfo.userInfo?.gender && setGender(userInfo.userInfo?.gender);
      userInfo.userInfo?.birthDate &&
        setBirthDate(
          //@ts-ignore
          new Date(+userInfo.userInfo?.birthDate.match("\\((.*?)-")?.pop())
            .toISOString()
            .slice(0, 10)
        );
      userInfo.userInfo?.email && setEmail(userInfo.userInfo?.email);
      userInfo.userInfo?.phoneNumber &&
        setPhoneNumber(userInfo.userInfo?.phoneNumber);
      userInfo.userInfo?.address && setAddress(userInfo.userInfo?.address);
      userInfo.userInfo?.passport?.serialAndNumber &&
        setSerialAndNumber(userInfo.userInfo?.passport.serialAndNumber);
      // @ts-ignore
      userInfo.userInfo?.passport?.dateOfIssue &&
        setDateIssue(
          new Date( //@ts-ignore
            +userInfo.userInfo?.passport.dateOfIssue.match("\\((.*?)-")?.pop()
          )
            .toISOString()
            .slice(0, 10)
        );
      userInfo.userInfo?.passport?.issuedOrganization &&
        setOrg(userInfo.userInfo?.passport.issuedOrganization);
      userInfo.userInfo?.passport?.identif &&
        setNumber(userInfo.userInfo?.passport.identif);

      loadSelected();
    } else {
      history.push("/");
    }
  }, []);

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  const TextHandler = (
    event: ChangeEvent<HTMLInputElement>,
    func: Function
  ): void => {
    let value = event.target.value;
    value = value.replace(/[^а-яА-ЯёЁa-zA-Z]/gi, "");
    func(value);
  };

  const SelectedBoxHandler = (event: SelectValue, func: Function): void => {
    func(event);
  };

  const BirthdayHandler = (event: Moment): void => {
    const years = moment.duration(moment().diff(event)).years();
    if (years >= 18) {
      let date = Date.parse(event.toString());
      setBirthDate(new Date(date).toISOString().slice(0, 10));
    } else {
      alert.error("Вам не должно быть меньше 18");
    }
  };

  const DatePassportHandler = (event: Moment): void => {
    const years = moment.duration(moment().diff(event)).years();
    if (years <= 10) {
      let date = Date.parse(event.toString());
      setDateIssue(new Date(date).toISOString().slice(0, 10));
    } else {
      alert.error("Паспорт не может быть выдан больше 10 лет назад");
    }
  };

  const EmailHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const SaveEditHandler = () => {
    // @ts-ignore
    saveEditData({
      firstName: firstName,
      name: name,
      fathersName: lastName,
      gender: gender,
      email: email,
      phoneNumber: phoneNumber,
      address: address,
      displayName: name,
      authId: auth.userId,
      roles: userInfo.userInfo?.roles,
      // @ts-ignore
      birthDate: birthDate !== 0 ? new Date(birthDate) : null,
      passport: {
        serialAndNumber: serialAndNumber,
        // @ts-ignore
        dateOfIssue: dateIssue !== 0 ? new Date(dateIssue) : null,
        issuedOrganization: org,
        identif: number,
      },
    });
    history.push("/userRoom/personalArea");
  };

  const loadSelected = async (): Promise<void> => {
    await loadOrganization().then((res) => setOrganization(res));
  };

  return (
    <div className="container">
      <h1>Редактирование данных аккаунта</h1>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 5 }}
        layout="horizontal"
        style={{ marginTop: "15px" }}
        initialValues={{ size: componentSize }}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
      >
        <Form.Item label="Email">
          <Input
            value={email}
            onChange={(event) => EmailHandler(event)}
            onBlur={() => {
              const regex =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (email && !regex.test(email))
                alert.error("Проверьте правильность написания email");
            }}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
          <Button type="primary" htmlType="submit" onClick={SaveEditHandler}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditProfile;
