export const SET_SECTION = "SET_SECTION";

type setSection = {
  type: typeof SET_SECTION;
  payload: number;
};

export type SectionAction = setSection;

export const setSectionAction = (index: number): setSection => {
  return {
    type: SET_SECTION,
    payload: index,
  };
};
