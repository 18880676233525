import React from "react";
import { Row, Col, Switch, Modal } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { ChangeRoleUserByUserId } from "../../../../assets/requests/changeRoleUser";
import { RemoveUser } from "../../../../assets/requests/dataForPanelAdmin";
import { stringify } from "querystring";
import { IRemoveUserResponse } from "../../../../assets/types/Types";

import removeIcon from "../../../../assets/images/recycle-bin-icon.svg"

enum Roles {
  Teacher = "Teacher",
  Adimn = "Admin",
  Guset = "Guest",
  User = "User",
}

const TEACHER = "Учитель";
const ADMIN = "Админ";

export const UserData: React.FC<any> = ({ item }) => {
  const [stateCheckTeacher, setStateCheckTeacher] = React.useState<any>(null);
  const [stateCheckAdmin, setStateCheckAdmin] = React.useState<any>(null);
  const [emailToRemove, setEmailToRemove] = React.useState<string>("");
  const [modalToRemoveActive, setModalToRemoveActive] = React.useState<boolean>(false);
  const [componentDisplay, setComponentDisplay] = React.useState<any>("flex");

  React.useEffect(() => {
    if (item && item.roles) {
      if (item.roles.includes(Roles.Adimn)) {
        setStateCheckAdmin(true);
      } else {
        setStateCheckAdmin(false);
      }
      if (item.roles.includes(Roles.Teacher)) {
        setStateCheckTeacher(true);
      } else {
        setStateCheckTeacher(false);
      }
    }
  }, [item]);

  const UpdateEmailToRemoveUser = () => {
    setEmailToRemove(item.email);
  } 

  const OnClickRemoveUser = () => {
    if (emailToRemove != null && emailToRemove != ""){
      Promise.resolve(
        RemoveUser(emailToRemove)
      ).then((res) => {
        if (res.data.message == "Удаление пользователя прошло успешно"){
          setComponentDisplay("none");
        }
      });
    }

    UnactivateModalToRemoveUser();
  }

 const UnactivateModalToRemoveUser = () =>{
   setModalToRemoveActive(false);
 }

  React.useEffect(() => {
    OnClickRemoveUser();
  }, [item, emailToRemove])

  return (
    <>
      {item && item.roles && (
        <Row
          className="usersList"
          style={{
            margin: "15px 0",
            textAlign: "justify",
            display: componentDisplay
          }}
        >
          <Col
            span={12}
            className = "title-with-delete-icon"
            style={{ padding: "0 10px", border: "0.5px solid #d9d9d9", display:"flex" }}
          >
            <p style={{ alignSelf: "center", marginBottom: 0 }}>{item.email}</p>

            <img
            title="Удалить"
            width="20.000000pt" height="20.000000pt"
            className="copy-link-img"
            onClick={() => {
              setModalToRemoveActive(true);
            }}
            src={removeIcon} />
          </Col>
          <Col
            span={12}
            style={{
              border: "0.5px solid #d9d9d9",
              padding: "0 10px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div className="role-of-user" style={{ display: "flex" }}>
              <span style={{ alignSelf: "center", marginRight: "10px" }}>
                {TEACHER}
              </span>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                style={{ alignSelf: "center" }}
                checked={stateCheckTeacher}
                onClick={() => {
                  if (!stateCheckTeacher && stateCheckAdmin) {
                    setStateCheckTeacher(true);
                    ChangeRoleUserByUserId(item.authId, [
                      Roles.Teacher,
                      Roles.Adimn,
                      Roles.Guset,
                      Roles.User,
                    ]);
                  }
                  if (!stateCheckTeacher && !stateCheckAdmin) {
                    setStateCheckTeacher(true);
                    ChangeRoleUserByUserId(item.authId, [
                      Roles.Teacher,
                      Roles.Guset,
                      Roles.User,
                    ]);
                  }
                  if (stateCheckTeacher && stateCheckAdmin) {
                    setStateCheckTeacher(false);
                    ChangeRoleUserByUserId(item.authId, [
                      Roles.Adimn,
                      Roles.Guset,
                      Roles.User,
                    ]);
                  }
                  if (stateCheckTeacher && !stateCheckAdmin) {
                    setStateCheckTeacher(false);
                    ChangeRoleUserByUserId(item.authId, [
                      Roles.Guset,
                      Roles.User,
                    ]);
                  }
                }}
              />
            </div>
            <div className="role-of-user" style={{ display: "flex" }}>
              <span style={{ alignSelf: "center", marginRight: "10px" }}>
                {ADMIN}
              </span>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                style={{ alignSelf: "center" }}
                checked={stateCheckAdmin}
                onClick={() => {
                  if (!stateCheckAdmin) {
                    setStateCheckAdmin(true);
                    setStateCheckTeacher(true);
                    ChangeRoleUserByUserId(item.authId, [
                      Roles.Teacher,
                      Roles.Adimn,
                      Roles.Guset,
                      Roles.User,
                    ]);
                  }
                  if (stateCheckAdmin && stateCheckTeacher) {
                    setStateCheckAdmin(false);
                    ChangeRoleUserByUserId(item.authId, [
                      Roles.Teacher,
                      Roles.Guset,
                      Roles.User,
                    ]);
                  }
                  if (stateCheckAdmin && !stateCheckTeacher) {
                    setStateCheckAdmin(false);
                    ChangeRoleUserByUserId(item.authId, [
                      Roles.Guset,
                      Roles.User,
                    ]);
                  }
                }}
              />
            </div>
          </Col>
          <Modal visible = {modalToRemoveActive} onOk = {UpdateEmailToRemoveUser} onCancel = {UnactivateModalToRemoveUser} >
            <div style={{
              textAlign: "center"
            }}>
              Вы действительно хотите удалить пользователя?
            </div>
          </Modal>
        </Row>
      )}
    </>
  );
};
