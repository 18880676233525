import {
  StateFetching,
  IsFetchingActions,
  SET_IS_FETCHING_LOADING,
  SET_IS_FETCHING_NONE,
  SET_IS_FETCHING_LOADED,
  SET_IS_FETCHING_ERROR,
} from "../actions/isFetching";

const initialState: StateFetching = StateFetching.Default;

export const isUserFetchingReducer = (
  state: StateFetching = initialState,
  action: IsFetchingActions
) => {
  switch (action.type) {
    case SET_IS_FETCHING_LOADING:
      return action.payload;

    case SET_IS_FETCHING_LOADED:
      return action.payload;

    case SET_IS_FETCHING_NONE:
      return action.payload;

    case SET_IS_FETCHING_ERROR:
      return action.payload;

    default:
      return state;
  }
};
