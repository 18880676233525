import {
  CLEAR_CURRENT_COURSE,
  CLEAR_CURRENT_TEST,
  CurrentCourseAction,
  SET_CURRENT_COURSE,
  SET_CURRENT_TEST,
} from "../actions/currentCourse";
import { ICourse } from "../../assets/types/Types";

export interface ICurrentCourse {
  currentCourse: ICourse | null;
  currentTestEvent: number | null;
}

const initialState: ICurrentCourse | null = null;

export const currentCourseReducer = (
  state: ICurrentCourse | null = initialState,
  action: CurrentCourseAction
) => {
  switch (action.type) {
    case SET_CURRENT_COURSE: {
      return {
        ...state,
        currentCourse: action.payload,
      };
    }

    case CLEAR_CURRENT_COURSE: {
      return {
        ...state,
        currentCourse: null,
      };
    }

    case SET_CURRENT_TEST: {
      return {
        ...state,
        currentTestEvent: action.payload,
      };
    }

    case CLEAR_CURRENT_TEST: {
      return {
        ...state,
        currentTestEvent: null,
      };
    }

    default:
      return state;
  }
};
