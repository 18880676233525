import React from "react";
import { List, Card } from "antd";
import { CheckSquareTwoTone } from "@ant-design/icons";
import { data } from "../../../assets/constants/constants";

const CardsContent: React.FC = () => {
  return (
    <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 3,
        xxl: 3,
      }}
      style={{ width: "100%" }}
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <Card
            style={{
              minHeight: 250,
              height: "100%",
            }}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <CheckSquareTwoTone
                  style={{
                    fontSize: 50,
                    color: "#1890ff",
                    marginRight: 10,
                  }}
                />
                <p className="card-title">{item.title}</p>
              </div>
            }
          >
            {item.text}
          </Card>
        </List.Item>
      )}
    />
  );
};

export default CardsContent;
