import axios from "axios";
import packageJson from "../../../package.json";
import { IEmailCheck, IRegistrationUser } from "../types/Types";

export const checkEmail = async (email: string): Promise<IEmailCheck> => {
	return await axios
    .post<IEmailCheck>(
      `${packageJson.server}/checkEmail/${email}?format=json`
    )
    .then((res) => res.data);
};

export const registrationUser = async (email: string): Promise<boolean> => {
  return axios
    .get<IRegistrationUser>(
      packageJson.server +
        "/createnewuser/" +
        email +
        "/" +
        window.location.host +
        "?format=json"
    )
    .then((res) => res.data.success);
};
