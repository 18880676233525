import packageJson from "../../../package.json";
import {
  CheckoutCourseForUser,
  ICreateTestForUserCourse,
  IPartStat,
  IQuestion,
  TypeCourseEvent,
  TypePaid,
} from "../types/Types";
import $api from "./http";

export const addCourseToUser = async (
  courseId: string,
  userId: string,
  typePaid: TypePaid = TypePaid.Paid
): Promise<void> => {
  const userCourses: CheckoutCourseForUser[] = await $api
    .get<CheckoutCourseForUser[]>(
      packageJson.server + `/getUsersCourse/${userId}?format=json`
    )
    .then((res) => res.data);

  if (userCourses.filter((item) => item.courseId === courseId).length === 0)
    await $api
      .get<TypeCourseEvent>(
        packageJson.server +
          `/addcoursetouser/${courseId}/${userId}/${typePaid}`
      )
      .then((res) => res.data);
};

export const createTestForUserCourse = async (
  userId: string,
  testTemplateId: string
): Promise<ICreateTestForUserCourse> => {
  return await $api
    .get<ICreateTestForUserCourse>(
      packageJson.server +
        `/generateTest/${userId}/${testTemplateId}?format=json`
    )
    .then((res) => res.data);
};

export const allQuestionsForTestEvent = async (
  testEvent: number
): Promise<IQuestion[]> => {
  return await $api
    .get<IQuestion[]>(
      packageJson.server + `/getAllTestQuestion/${testEvent}?format=json`
    )
    .then((res) => res.data);
};

export const GetFullStatForCourse = async (
  CourceId: string,
  userId: string
): Promise<IPartStat[]> => {
  return await $api
    .post<IPartStat[]>(
      packageJson.server + `/allStatistic`,
      { CourceId, userId },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.data);
};
