export interface IPassport {
  serialAndNumber: string;
  dateOfIssue: string;
  issuedOrganization: string;
  identif: string;
}

export interface IUserInfo {
  firstName: string;
  name: string;
  fathersName: string;
  birthDate: string;
  gender: string;
  email: string;
  phoneNumber: string;
  address: string;
  displayName: string;
  roles: string[];
  login: string;
  passport: IPassport;
}

export interface IUserCourses {
  courseId: string;
  name: string;
  defenition: string;
  progress: number;
  maxProgress: number;
  isPaided: boolean;
  courseIdHash: string;
}

export interface IImage {
  id: string;
  contentType: string;
  blobData: string;
}

export interface IUserGeneralInfo {
  userInfo: IUserInfo | null;
  coursesInfo: IUserCourses[] | null;
  avatarUser: IImage | null;
}

export const SET_USER_INFO = "SET_USER_INFO";
export const CLEAR_USER_INFO = "CLEAR_USER_INFO";

type setUserIfo = {
  type: typeof SET_USER_INFO;
  payload: IUserGeneralInfo;
};

type clearUserInformation = {
  type: typeof CLEAR_USER_INFO;
  payload: IUserGeneralInfo;
};

export type UserInfoAction = setUserIfo | clearUserInformation;

export const setUserInfo = (user: IUserGeneralInfo): setUserIfo => {
  return {
    type: SET_USER_INFO,
    payload: user,
  };
};

export const clearUserInfo = (): clearUserInformation => {
  let info: IUserGeneralInfo = {
    userInfo: null,
    coursesInfo: null,
    avatarUser: null,
  };
  return {
    type: CLEAR_USER_INFO,
    payload: info,
  };
};
