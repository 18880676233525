import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./store/reducers";
import Header from "./components/header/Header";
import Slider from "./components/slider/Slider";
import MainPageContent from "./components/Pages/mainPageContent/MainPageContent";
import Footer from "./components/footer/Footer";
import Tests from "./components/Pages/tests/Tests";
import Contacts from "./components/contacts/Contacts";
import UserRoom from "./components/userRoom/UserRoom";
import AdminRoom from "./components/adminRoom/AdminRoom";
import EditProfile from "./components/userRoom/EditProfile";
import ChangePassword from "./components/userRoom/ChangePassword";
import InfoTest from "./components/passingTest/infoTest";
import TestEvent from "./components/passingTest/testEvent";
import StatisticsForCourse from "./components/userRoom/StatisticsForCourse";
import LoadStatOfTestEvent from "./components/passingTest/LoadStatOfTestEvent";
import { getAuthAction } from "./store/actions/authenticate";
import { checkAuth } from "./assets/requests/checkAuth";
import { useDispatch } from "react-redux";
import { Layout, Spin } from "antd";
import {
  setIsFetchingNone,
  setIsFetchingLoading,
  setIsFetchingLoaded,
  StateFetching,
} from "./store/actions/isFetching";
import CourseEdit from "./components/adminRoom/Course/CourseEdit";
import AddCourse from "./components/adminRoom/Course/AddCourse";
import { HelpInfo } from "./components/passingTest/checkInfo/HelpInfo";

const { Content } = Layout;

const App = () => {
  let auth = useSelector((state: RootState) => state.auth);
  let currentCourse = useSelector((state: RootState) => state.currentCourse);
  let isFetching = useSelector((state: RootState) => state.isUserFetching);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (localStorage.getItem("token") != null) {
      checkAuth().then((res) => {
        dispatch(setIsFetchingLoading());
        dispatch(getAuthAction(res));
        dispatch(setIsFetchingLoaded());
      });
    } else {
      dispatch(setIsFetchingNone());
    }
  }, []);

  return (
    <Layout>
      <Header />
      <Content style={{ flexGrow: 1 }}>
        <Route path={"/"} exact>
          <Slider />
          <MainPageContent />
        </Route>
        <Route exact path={"/tests"}>
          <Tests />
        </Route>
        <Route exact path={"/about"}>
          <MainPageContent />
        </Route>
        <Route
          exact
          path={"/helpInfo/id:id"}
          render={(props) => (
            <HelpInfo id={props.match.params.id.replace(":", "")} />
          )}
        />
        <Route exact path={"/contacts"}>
          <Contacts />
        </Route>
        <Route
          exact
          path={"/userRoom/personalArea"}
          render={() =>
            isFetching === StateFetching.Loaded ? (
              <UserRoom />
            ) : isFetching === StateFetching.Loading ? (
              <Spin className="preloader" tip="Loading..." />
            ) : (
              isFetching === StateFetching.None && <Redirect to={"/"} />
            )
          }
        />
        <Route
          exact
          path={"/userRoom/changePassword"}
          render={() =>
            isFetching === StateFetching.Loaded ? (
              <ChangePassword />
            ) : isFetching === StateFetching.Loading ? (
              <Spin className="preloader" tip="Loading..." />
            ) : (
              isFetching === StateFetching.None && <Redirect to={"/"} />
            )
          }
        />
        <Route
          exact
          path={"/userRoom/editProfile"}
          render={() =>
            isFetching === StateFetching.Loaded ? (
              <EditProfile />
            ) : isFetching === StateFetching.Loading ? (
              <Spin className="preloader" tip="Loading..." />
            ) : (
              isFetching === StateFetching.None && <Redirect to={"/"} />
            )
          }
        />
        <Route
          exact
          path={"/userRoom/editProfile/loadStatisticOfCourse/id:id"}
          render={(props) => 
            isFetching === StateFetching.Loaded ? (
              <StatisticsForCourse
                courseId={props.match.params.id.replace(":", "")}
                userID={auth.userId}
              />
            ) : isFetching === StateFetching.Loading ? (
              <Spin className="preloader" tip="Loading..." />
            ) : (
              isFetching === StateFetching.None && <Redirect to={"/"} />
            )
          }
        />

        <Route
          path={"/tests/endtTest/testEventID:testEventID"}
          render={(props) => (
            <LoadStatOfTestEvent
              testEventID={Number.parseInt(
                props.match.params.testEventID.replace(":", "")
              )}
            />
          )}
        />

        <Route
          exact
          path={"/test/started/id:id/rA:rA/rQ:rQ"}
          render={(props) =>
            auth !== null && currentCourse !== null ? (
              // @ts-ignore
              <TestEvent
                testTemplateId={props.match.params.id.replace(":", "")}
                isRandomAnswer={JSON.parse(
                  props.match.params.rA?.replace(":", "")
                )}
                isRandomQuestion={JSON.parse(
                  props.match.params.rQ?.replace(":", "")
                )}
              />
            ) : (
              <Redirect to="/" />
            )
          }
        />

        <Route
          exact
          path={"/course/id:id"}
          render={(props) => (
            <InfoTest courseId={props.match.params.id.replace(":", "")} />
          )}
        />
        <Route
          exact
          path={"/adminRoom/CourseEdit/id:id"}
          render={(props) =>
            isFetching === StateFetching.Loaded ? (
              <CourseEdit id={parseInt(props.match.params.id?.replace(":", ""),10)} />
            ) : isFetching === StateFetching.Loading ? (
              <Spin className="preloader" tip="Loading..." />
            ) : (
              isFetching === StateFetching.None && <Redirect to={"/"} />
            )
          }
        />
        <Route
          exact
          path={"/adminRoom/CourseAdd"}
          render={() =>
            isFetching === StateFetching.Loaded ? (
              <AddCourse />
            ) : isFetching === StateFetching.Loading ? (
              <Spin className="preloader" tip="Loading..." />
            ) : (
              isFetching === StateFetching.None && <Redirect to={"/"} />
            )
          }
        />
        <Route
          exact
          path={"/adminRoom/"}
          render={() =>
            isFetching === StateFetching.Loaded ? (
              <AdminRoom />
            ) : isFetching === StateFetching.Loading ? (
              <Spin className="preloader" tip="Loading..." />
            ) : (
              isFetching === StateFetching.None && <Redirect to={"/"} />
            )
          }
        />
      </Content>
      <Footer />
    </Layout>
  );
};

export default App;
