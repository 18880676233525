export const SET_IS_FETCHING_LOADING = "SET_IS_FETCHING_LOADING";
export const SET_IS_FETCHING_NONE = "SET_IS_FETCHING_NONE";
export const SET_IS_FETCHING_LOADED = "SET_IS_FETCHING_LOADED";
export const SET_IS_FETCHING_ERROR = "SET_IS_FETCHING_ERROR";

export enum StateFetching {
  Loading = "authing",
  Error = "error auth",
  Loaded = "auth",
  None = "no auth",
  Default = "default",
}

type setLoading = {
  type: typeof SET_IS_FETCHING_LOADING;
  payload: StateFetching.Loading;
};

type setLoaded = {
  type: typeof SET_IS_FETCHING_LOADED;
  payload: StateFetching.Loaded;
};

type setError = {
  type: typeof SET_IS_FETCHING_ERROR;
  payload: StateFetching.Error;
};

type setNone = {
  type: typeof SET_IS_FETCHING_NONE;
  payload: StateFetching.None;
};

export type IsFetchingActions = setLoading | setLoaded | setError | setNone;

export const setIsFetchingLoading = (): setLoading => {
  return {
    type: SET_IS_FETCHING_LOADING,
    payload: StateFetching.Loading,
  };
};

export const setIsFetchingLoaded = (): setLoaded => {
  return {
    type: SET_IS_FETCHING_LOADED,
    payload: StateFetching.Loaded,
  };
};

export const setIsFetchingNone = (): setNone => {
  return {
    type: SET_IS_FETCHING_NONE,
    payload: StateFetching.None,
  };
};

export const setIsFetchingError = (): setError => {
  return {
    type: SET_IS_FETCHING_ERROR,
    payload: StateFetching.Error,
  };
};
