import {
  CLEAR_USER_INFO,
  IUserGeneralInfo,
  SET_USER_INFO,
  UserInfoAction,
} from "../actions/userInfo";

const initialState: IUserGeneralInfo | null = null;

export const userInfoReducer = (
  state: IUserGeneralInfo | null = initialState,
  action: UserInfoAction
) => {
  switch (action.type) {
    case SET_USER_INFO: {
      return {
        ...state,
        userInfo: action.payload.userInfo,
        coursesInfo: action.payload.coursesInfo,
        avatarUser: action.payload.avatarUser,
      };
    }
    case CLEAR_USER_INFO: {
      return null;
    }
    default:
      return state;
  }
};
