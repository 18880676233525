import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUserData } from "../../../../store/actions/authenticate";
import { Typography } from "antd";
import { Menu, Button } from "antd";
import { setIsFetchingNone } from "../../../../store/actions/isFetching";
import { setMenuItem } from "../../../../store/actions/navBarItem";

const { Title } = Typography;
const { SubMenu } = Menu;

interface AuthModalProps {
  name: string;
  roles?: string[];
}

const AuthModal: React.FC<AuthModalProps> = React.memo(({ name, roles }) => {
  const [visibleDropList, setVisibleDropList] = React.useState<boolean>(false);
  const refUser = React.useRef<HTMLHeadingElement>(null);

  React.useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();

  const toggleVisiblePopup = (): void => setVisibleDropList(!visibleDropList);

  const exitHandler = () => {
    dispatch(clearUserData());
    dispatch(setIsFetchingNone());
    localStorage.setItem("navLink", "mainPage");
    history.push("/");
  };

  const handleOutsideClick = React.useCallback((e: MouseEvent) => {
    if (refUser.current && !e.composedPath().includes(refUser.current)) {
      setVisibleDropList(false);
    }
  }, []);

  return (
    <div ref={refUser} className="user_drop_list">
      <SubMenu
        key="SubMenu"
        title={
          <Title
          level={4}>
            {name}
            <i
              className="fas fa-chevron-down"
              style={{ fontSize: 16, marginLeft: 10 }}
            />
          </Title>
        }
      >
        <Menu.Item key="user">
          <NavLink
            to="/userRoom/personalArea"
            onClick={() => {
              localStorage.setItem("navLink", "userRoom");
              dispatch(setMenuItem({ navBarItem: "userRoom" }));
              setVisibleDropList(false);
            }}
          >
            Личный кабинет
          </NavLink>
        </Menu.Item>
        {(roles?.includes("Admin") || roles?.includes("Teacher")) && (
          <Menu.Item key="admin">
            <NavLink
              to="/adminRoom"
              onClick={() => {
                localStorage.setItem("navLink", "adminRoom");
                dispatch(setMenuItem({ navBarItem: "adminRoom" }));
                localStorage.setItem("curentMenuItem", `Courses`);
                localStorage.setItem("paginationUsers", "1");
                localStorage.setItem("paginationCourses", "1");
                setVisibleDropList(false);
              }}
            >
              Страница администратора
            </NavLink>
          </Menu.Item>
        )}
        <Menu.Item key="exit" style={{ textAlign: "center" }}>
          <Button className="ant-btn-primary btn-link" onClick={exitHandler}>
            Выход
          </Button>
        </Menu.Item>
      </SubMenu>
    </div>
  );
});

export default AuthModal;
