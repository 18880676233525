import * as React from "react";
import { IListQuestion } from "../testEvent";
import { Typography } from "antd";
import { IQuestion } from "../../../assets/types/Types";

const { Text } = Typography;

interface StatusBarQuestionProp {
  list: IListQuestion[];
  currentQuestion: IQuestion;
}

const StatusBarQuestion: React.FC<StatusBarQuestionProp> = ({
  list,
  currentQuestion,
}) => {
  const [curQuestion, setCurQuestion] = React.useState<IQuestion>();
  const [listQuestion, setListQuestion] = React.useState<IListQuestion[]>();

  React.useEffect(() => {
    if (list.filter((item) => item.order === 0).length > 1) {
      const newList = list.map((item, index) => {
        item.order = index;
        return item;
      });
      setListQuestion(newList);
    } else {
      setListQuestion(list.sort((a, b) => a.order - b.order));
    }
  }, [list]);

  React.useEffect(() => {
    if (list.filter((item) => item.order === 0).length > 1) {
      const index = list
        .sort((a, b) => a.order - b.order)
        ?.find((item) => item.isRight === null)?.question;
      setCurQuestion(index);
    } else {
      setCurQuestion(currentQuestion);
    }
  }, [currentQuestion]);

  const styleText = {
    marginRight: "20px",
  };

  return (
    <div
      className="header-test"
      style={{ fontSize: "20px", marginTop: "20px" }}
    >
      <Text style={styleText}>
        Текущий вопрос:{" "}
        {listQuestion &&
          curQuestion &&
          listQuestion.indexOf(
            //@ts-ignore
            listQuestion?.find(
              //@ts-ignore
              (item) => item.question.id === currentQuestion.id
            )
          ) + 1}
      </Text>
      <Text style={styleText}>
        Пройдено вопросов:{" "}
        {listQuestion &&
          listQuestion.filter((item) => item.isRight !== null).length}
      </Text>
      <Text style={styleText}>
        Всего: {listQuestion && listQuestion.length}
      </Text>
    </div>
  );
};

export default StatusBarQuestion;
