import { ICourse } from "../../assets/types/Types";

export const SET_CURRENT_COURSE = "SET_CURRENT_COURSE";
export const CLEAR_CURRENT_COURSE = "CLEAR_CURRENT_COURSE";

export const SET_CURRENT_TEST = "SET_CURRENT_TEST";
export const CLEAR_CURRENT_TEST = "CLEAR_CURRENT_TEST";

type setCurrentCourse = {
  type: typeof SET_CURRENT_COURSE;
  payload: ICourse;
};

type clearCurrentCourse = {
  type: typeof CLEAR_CURRENT_COURSE;
  payload: null;
};

type setCurrentTest = {
  type: typeof SET_CURRENT_TEST;
  payload: number;
};

type clearCurrentTest = {
  type: typeof CLEAR_CURRENT_TEST;
  payload: null;
};

export type CurrentCourseAction =
  | setCurrentCourse
  | clearCurrentCourse
  | setCurrentTest
  | clearCurrentTest;

export const setCurrentCourseAction = (course: ICourse): setCurrentCourse => {
  return {
    type: SET_CURRENT_COURSE,
    payload: course,
  };
};

export const clearCurrentCourseAction = (): clearCurrentCourse => {
  return {
    type: CLEAR_CURRENT_COURSE,
    payload: null,
  };
};

export const setCurrentTestAction = (testEvent: number): setCurrentTest => {
  return {
    type: SET_CURRENT_TEST,
    payload: testEvent,
  };
};

export const clearCurrentTestAction = (): clearCurrentTest => {
  return {
    type: CLEAR_CURRENT_TEST,
    payload: null,
  };
};
