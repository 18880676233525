import React from "react";
import {
  getAllCoursesForAdimn,
  getAutoDeleteFlag,
  startAutoDelete,
  updateAutoDeleteFlag,
} from "../../../assets/requests/courses";
import { Button, Row, Col, Pagination, Input, Switch, Modal } from "antd";
import { useHistory } from "react-router";
import {
  SortAscendingOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { CoursesListData } from "./CourseListComponent/CourseListData";
import moment from "moment";
import {RootState} from "../../../store/reducers";
import {useSelector} from "react-redux";

export interface ICoursesWithLink {
  id: string;
  uniqId: number;
  link: string;
  name: string;
  tags: string[];
  createdDate: string;
  note: string;
  courseIdHash: string;
  lifeTime: number
}

export const CoursesListContent: React.FC<any> = () => {
  const [array, setArray] = React.useState<ICoursesWithLink[] | undefined>();
  const [arraySearch, setArraySearch] = React.useState<
    ICoursesWithLink[] | undefined
  >();
  const [coursesPerPage, setCoursesPerPage] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [arrayForPagination, setArrayForPagination] = React.useState<
    ICoursesWithLink[] | undefined
  >();
  const [stateFilterLink, setStateFilterLink] = React.useState<boolean>(false);
  const [stateFilterName, setStateFilterName] = React.useState<boolean>(false);
  const [stateFilterDate, setStateFilterDate] = React.useState<boolean>(false);
  const [stateCheckDeleteAll, setStateCheckDeleteAll] =
    React.useState<boolean>();
  const [visibleModal, setVisibleModal] = React.useState<boolean>();

  const history = useHistory();

  const auth = useSelector((state: RootState) => state.auth);

  React.useEffect(() => {
    Promise.resolve(getAllCoursesForAdimn()).then((res) => setArray(res));
    const pagination = localStorage.getItem("paginationCourses");
    if (!pagination) {
      localStorage.setItem("paginationCourses", "1");
    }
    Promise.resolve(getAutoDeleteFlag()).then((res) => {
      setStateCheckDeleteAll(res.flag);
    });
  }, []);

  React.useEffect(() => {
    const nowDate = moment();
    const dateDelete = moment(localStorage.getItem("autoDeleteDate"));
    if (!stateCheckDeleteAll) {
      if (nowDate.isAfter(dateDelete, "day")) {
        localStorage.setItem("autoDeleteDate", moment().toString());
        setVisibleModal(true);
      }
    }
  }, [stateCheckDeleteAll]);

  React.useEffect(() => {
    if (array) {
      setArraySearch(array);
    }
    const pagination = localStorage.getItem("paginationCourses");
    if (pagination) {
      setCurrentPage(Number(pagination));
    }
  }, [array]);

  React.useEffect(() => {}, [stateCheckDeleteAll]);

  React.useEffect(() => {
    if (arraySearch) {
      const lastIndex = currentPage * coursesPerPage;
      const firstIndex = lastIndex - coursesPerPage;
      setArrayForPagination(arraySearch?.slice(firstIndex, lastIndex));
      const pagination = localStorage.getItem("paginationCourses");
      if (pagination) {
        setCurrentPage(Number(pagination));
      }
    }
  }, [array, currentPage, arraySearch]);

  const handlePaginationChange = (
    page: number,
    size: number | undefined
  ): void => {
    if (!size) return;

    if (size === coursesPerPage) {
      setCurrentPage(page);
      localStorage.setItem("paginationCourses", page.toString());
    } else {
      const newPage =
        1 + Math.floor((coursesPerPage / size) * (currentPage - 1));
      setCoursesPerPage(size);
      setCurrentPage(newPage);
      localStorage.setItem("paginationCourses", newPage.toString());
    }
  };

  const searchItems = (value: string) => {
    let newListCourse: ICoursesWithLink[] | undefined = [];
    if (array) {
      for (let key of array) {
        let { name } = key;
        let { link } = key;

        name = name.toLowerCase();

        if (name && link) {
          name = name.toLowerCase();
          if (name.includes(value.toLowerCase())) {
            newListCourse.push(key);
          } else if (link.includes(value.toLowerCase())) {
            newListCourse.push(key);
          }
        } else if (link) {
          link = link.toLowerCase();
          if (link.includes(value.toLowerCase())) {
            newListCourse.push(key);
          }
        } else if (name) {
          name = name.toLowerCase();
          if (name.includes(value.toLowerCase())) {
            newListCourse.push(key);
          }
        }
      }
      setArraySearch(newListCourse);
      localStorage.setItem("paginationCourses", "1");
      setCurrentPage(1);
    }
  };

  const sortBy = (tag: string) => {
    const arr = [...(arraySearch ?? [])];
    setArraySearch([]);
    if (tag === "Link" && arr) {
      if (stateFilterLink === false)
        setArraySearch(arr?.sort((a, b) => (a.link > b.link ? 1 : -1)));
      else setArraySearch(arr?.sort((a, b) => (a.link < b.link ? 1 : -1)));

      setStateFilterLink(!stateFilterLink);
    }
    if (tag === "Name" && arr) {
      if (stateFilterName === false)
        setArraySearch(
          arr?.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
        );
      else
        setArraySearch(
          arr?.sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
          )
        );

      setStateFilterName(!stateFilterName);
    }
    if (tag === "Date" && arr) {
      if (stateFilterDate === true)
        setArraySearch(
          arr.sort(function (a, b) {
            if (a.lifeTime === undefined && b.lifeTime === undefined)
              return 0;
            if (a.lifeTime === undefined) return -1;
            if (b.lifeTime === undefined) return 1;
            else {
              let dateA = a.lifeTime,
                dateB = b.lifeTime;
              if (moment(dateA).isSame(dateB)) return 0;
              if (moment(dateA).isAfter(dateB)) return -1;
              else return 1;
            }
          })
        );
      else
        setArraySearch(
          arr.sort(function (a, b) {
            if (a.lifeTime === undefined && b.lifeTime === undefined)
              return 0;
            if (a.lifeTime === undefined) return 1;
            if (b.lifeTime === undefined) return -1;
            else {
              let dateA = a.lifeTime,
                dateB = b.lifeTime;
              if (moment(dateA).isSame(dateB)) return 0;
              if (moment(dateA).isAfter(dateB)) return 1;
              else return -1;
            }
          })
        );

      setStateFilterDate(!stateFilterDate);
    }
  };

  const handleOk = () => {
    startAutoDelete();
    setVisibleModal(false);
    history.push("/adminRoom/");
  };

  const hideModal = () => {
    setVisibleModal(false);
  };

  return (
    <>
    {auth.roles.includes("Admin") && (
      <div className="action-with-course">
        <Button
          type="primary"
          style={{ marginTop: "10px", alignSelf: "center" }}
          onClick={() => history.push("/adminRoom/CourseAdd")}
        >
          Добавить ссылку
        </Button>
      </div>)}

      <div style={{ display: "flex", marginTop: "15px" }}>
        <span
          style={{
            alignSelf: "end",
            fontSize: "15px",
            fontWeight: "bolder",
            width: "105px",
          }}
        >
          Поиск ссылки:
        </span>
        <Input
          placeholder="Пример: Курс водителей такси или https://sdo.pto.by/course/id:00000000000000000000000000000000"
          style={{ marginLeft: "20px" }}
          onChange={(item) => searchItems(item.target.value.trim())}
          defaultValue={""}
        />
      </div>
      <p style={{ marginTop: "10px", color: "black", marginBottom: "0px" }}>
        Все ссылки:
      </p>
      <Row className="courses-info-title" style={{ textAlign: "center" }}>
        <Col
          span={12}
          style={{
            borderLeft: "0.5px solid rgb(24, 144, 255)",
            borderRight: "0.5px solid rgb(24, 144, 255)",
            backgroundColor: "white",
          }}
        >
          Ссылка
          <Button
            style={{ border: "0px", marginRight: "auto" }}
            onClick={() => sortBy("Link")}
          >
            <SortAscendingOutlined />
          </Button>
        </Col>
        <Col
          span={6}
          style={{
            borderRight: "0.5px solid rgb(24, 144, 255)",
            backgroundColor: "white",
          }}
        >
          Название
          <Button
            style={{ border: "0px", marginRight: "auto" }}
            onClick={() => sortBy("Name")}
          >
            <SortAscendingOutlined />
          </Button>
        </Col>
        <Col
          span={3}
          style={{
            borderRight: "0.5px solid rgb(24, 144, 255)",
            backgroundColor: "white",
          }}
        >
          Активна дней:
          <Button
            style={{ border: "0px", marginRight: "auto" }}
            onClick={() => sortBy("Date")}
          >
            <SortAscendingOutlined />
          </Button>
        </Col>
      </Row>
      {arrayForPagination &&
        arrayForPagination.map((item: ICoursesWithLink, index) => (
          <CoursesListData key={index} item={item} />
        ))}
      <Pagination
        onChange={handlePaginationChange}
        current={currentPage}
        total={arraySearch?.length}
        pageSize={coursesPerPage}
        showSizeChanger={false}
        hideOnSinglePage
        responsive
        size={"default"}
        style={{ alignSelf: "center", marginTop: 20 }}
      />
      <Modal
        title="Удаление"
        visible={visibleModal}
        onOk={handleOk}
        onCancel={hideModal}
        okText="Да"
        cancelText="Нет"
      >
        <p>Хотите удалить итсёкшие по времени ссылки?</p>
      </Modal>
    </>
  );
};
