import React from "react";
import { Button, Row, Col } from "antd";
import moment from "moment";
import { useHistory } from "react-router";
import { ICoursesWithLink } from "../CoursesList";
import copyIcon from "../../../../assets/images/copy-icon.svg"
import copy from "copy-to-clipboard";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/reducers";

enum ColorCourseSuccess {
  Valid = "#f6ffed",
  NoValid = "#fff2f0",
}

interface ICourseData {
  item: ICoursesWithLink;
}

export const CoursesListData: React.FC<ICourseData> = ({ item }) => {
  const [colorValidCourse, setColorValidCourse] = React.useState<string>(
    ColorCourseSuccess.Valid
  );

  const auth = useSelector((state: RootState) => state.auth);

  React.useEffect(() => {
    validateDateCheck(item);
  }, [item]);

  const history = useHistory();

  const validateDateCheck = (item: ICoursesWithLink): boolean => {
    let now = new Date();
    let courseDate = new Date(moment(item.createdDate).toString());
    courseDate.setDate(courseDate.getDate() + item.lifeTime)
    if (item.lifeTime != 0) {
      if (now > courseDate) {
        setColorValidCourse(ColorCourseSuccess.NoValid);
        return false;
      } else {
        setColorValidCourse(ColorCourseSuccess.Valid);
        return true;
      }
    } else {
      setColorValidCourse(ColorCourseSuccess.Valid);
      return true;
    }
  };

  const onClickCopyLink = () => {
    const linkCopy = item.link;

    if (linkCopy) copy(linkCopy);
  };

  return (
    <Row
      className="coursesList"
      style={{
        margin: "15px 0",
        textAlign: "justify",
        backgroundColor: colorValidCourse,
      }}
    >
      <Col
        span={12}
        style={{
          border: "0.5px solid #d9d9d9",
          padding: "0 10px",
          display: "flex",
        }}
      >
        <p
          style={{
            alignSelf: "center",
            marginBottom: 0,
            textAlign: "justify",
            fontSize: "13px",
          }}
        >
          <a
            title="Перейти по ссылке"
            className="course-link-item"
            href={item.link}
          >
            {item.link}
          </a>
          
        </p>
        <img
          title="Копировать ссылку"
          width="20.000000pt" height="20.000000pt"
          className="copy-link-img"
          onClick={() => {
            onClickCopyLink();
          }}
          src={copyIcon} />
      </Col>
      <Col
        span={6}
        style={{
          padding: "0 10px",
          border: "0.5px solid #d9d9d9",
          display: "flex",
        }}
      >
        <p
          style={{
            alignSelf: "center",
            marginBottom: 0,
            textAlign: "justify",
            fontSize: "13px",
          }}
        >
          {item.name}
        </p>
      </Col>
        <Col
          span={3}
          style={{
            padding: "0 10px",
            border: "0.5px solid #d9d9d9",
            display: "flex",
          }}
        >
          <p style={{ alignSelf: "center", marginBottom: 0, fontSize: "13px" }}>
              {item.lifeTime == 0 ? "Без лимита" : item.lifeTime}
          </p>
        </Col>
      <Col
        className="btn-edit-course-box"
        span={3}
        style={{
          justifyContent: "center",
          border: "0px",
          display: "flex",
          textAlign: "center",
          backgroundColor: "rgb(240 242 245)",
        }}
      >
      {auth.roles.includes("Admin") && (
        <Button
          className="btn-edit-course"
          style={{ alignSelf: "center", marginBottom: 0, fontSize: "12px" }}
          onClick={() =>
            history.push(`/adminRoom/CourseEdit/id:${item.uniqId}`)
          }
        >
          <span style={{ fontSize: "12px" }}>Редактировать ссылку</span>
        </Button>)}
      </Col>
    </Row>
  );
};
