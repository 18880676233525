import { SectionAction, SET_SECTION } from "../actions/section";

export interface ISectionState {
  index: number;
}

const initialState: ISectionState = {
  index: 0,
};

export const sectionReducer = (
  state: ISectionState = initialState,
  action: SectionAction
) => {
  switch (action.type) {
    case SET_SECTION:
      return {
        ...state,
        index: action.payload,
      };
    default:
      return state;
  }
};
