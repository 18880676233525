import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { Link } from "react-router-dom";
import { LoadData } from "../../assets/requests";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/actions/userInfo";
import { setSectionAction } from "../../store/actions/section";
import { saveEditAvatar } from "../../assets/requests";
import { Upload, Button, Image, Card, Row, Col, Avatar } from "antd";
import { UploadOutlined, ReadOutlined, UserOutlined } from "@ant-design/icons";

const UserRoom: React.FC = () => {
  const auth = useSelector((state: RootState) => state.auth);
  let userInfo = useSelector((state: RootState) => state.userInfo);

  const [avatar, setAvatar] = React.useState<string | undefined>();

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setSectionAction(-1));
    Promise.resolve(LoadData(auth.userId)).then((res) =>
      dispatch(setUserInfo(res))
    );
  }, []);

  React.useEffect(() => {
    userInfo?.avatarUser?.blobData &&
      setAvatar(
        `${userInfo.avatarUser?.contentType}, ${userInfo.avatarUser?.blobData}`
      );
  }, [userInfo]);

  const SaveUserAvatar = (blob: string) => {
    saveEditAvatar(
      {
        id: userInfo.avatarUser?.id,
        blobData: blob.split(",")[1],
        contentType: blob.split(",")[0],
      },
      auth.userId
    ).then((res) => {
      res.result && setAvatar(`${blob.split(",")[0]}, ${blob.split(",")[1]}`);
    });
  };

  return (
    <div className="container base-container">
      <div className="userroom">
        <h3
        style={{
          textAlign: "center",
          marginBottom: "15px",
          marginTop: "15px",
          fontWeight: 600,
          fontSize: "24px"
        }}
        >
          Личный кабинет пользователя
        </h3>
        {userInfo && (
          <>
            <div className="fio">
              <div className="avatar">
                <div className="change-avatar">
                  {typeof userInfo.avatarUser != "string" &&
                  userInfo.avatarUser?.blobData ? (
                    <Image
                      style={{ objectFit: "cover" }}
                      width={100}
                      src={avatar}
                    />
                  ) : (
                    <Avatar
                      className="avatar"
                      style={{ marginRight: "0px" }}
                      shape="square"
                      size={100}
                      icon={<UserOutlined />}
                    />
                  )}
                  <div className="change-avatar-items">
                    {userInfo.userInfo?.email && (
                      <span>{`${userInfo.userInfo?.email}`} </span>
                    )}

                    <Upload
                      action={avatar}
                      listType="picture"
                      showUploadList={false}
                      className="btn-upload"
                      onChange={({ file, fileList }) => {
                        const { status } = file;
                        if (status === "done") {
                          let reader = new FileReader();

                          const fileAdd = fileList[fileList.length - 1];

                          if (fileAdd?.originFileObj) {
                            reader.readAsDataURL(fileAdd.originFileObj);
                            reader.onloadend = function (e) {
                              if (reader.result)
                                SaveUserAvatar(reader.result?.toString());
                            };
                          }
                        }
                      }}
                    >
                      <Button
                        type="primary"
                        icon={<UploadOutlined style={{ marginLeft: 0 }} />}
                      >
                        Загрузить новый аватар
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
            </div>
            <div className="address">
              {userInfo.userInfo?.address && (
                <>
                  <i className="fas fa-map-marker-alt"></i>{" "}
                  {userInfo.userInfo?.address}
                </>
              )}
            </div>
            <div className="date">
              {userInfo.userInfo?.birthDate && (
                // @ts-ignore
                <>
                  <i className="fas fa-calendar-alt"></i>{" "}
                  {new Date( //@ts-ignore
                    +userInfo.userInfo?.birthDate.match("\\((.*?)-")?.pop()
                  ).toLocaleDateString("ru-RU")}
                </>
              )}
            </div>
            <div className="btnChangeProfile" style={{ marginBottom: "20px" }}>
              <Link
                style={{ marginLeft: 0 }}
                to="/userRoom/editProfile"
                className="ant-btn-primary btn-link"
              >
                Изменить Email
              </Link>
              <Link
                to="/userRoom/changePassword"
                className="ant-btn-primary btn-link"
              >
                Изменить пароль
              </Link>
            </div>
            <h2>Ваши курсы</h2>
            {userInfo.coursesInfo && (
              <Row className="person-course-row" gutter={[16, 16]} justify={"space-between"}>
                {userInfo.coursesInfo.map((item, index) => (
                  <Col span={8} key={index + item.courseId}>
                    <Card title={<ReadOutlined />} hoverable bordered={false}>
                      <p style={{ height: "40%", marginBottom: 0 }}>
                        {" "}
                        {item.name}
                      </p>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/userRoom/editProfile/loadStatisticOfCourse/id:${item.courseId}`}
                        className="ant-btn-primary btn-link"
                      >
                        Ваша статистика
                      </Link>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserRoom;
