import React, { ChangeEvent } from "react";
import FormRegistration from "./FormRegistration";
// @ts-ignore
import { useAlert } from "react-alert";
import { checkEmail, registrationUser } from "../../../../assets/requests";
import { Button } from "antd";

const Registration: React.FC = () => {
  const [visibleForm, setVisibleForm] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>();

  const alert = useAlert();

  const visibleHandler = (): void => {
    setVisibleForm(true);
  };
  const hideHandler = (): void => setVisibleForm(false);

  const emailHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (email !== event.target.value) setEmail(event.target.value.trim());
  };

  const authHandler = async () => {
    if (email) {
      const response = await checkEmail(email);
			console.log(response)
      if (response.isUserNotExists) {
        await registrationUser(email)
          .then((res) => {
            if (res) {
              setVisibleForm(false);
              alert.info(
                "Данные для авторизации отправлены на ваш email - " + email
              );
            } else {
              alert.error("Ошибка регистрации");
            }
          })
          .catch(() => {
            alert.error("Ошибка запроса регистрации");
          });
      } else {
        alert.error("Пользователь уже зарегестрирован");
      }
    }
  };

  return (
    <>
      <Button style={{ marginRight: 10 }} onClick={visibleHandler}>
        Регистрация
      </Button>
      {visibleForm && (
        <FormRegistration
          visibleLogInForm={visibleForm}
          handleClose={hideHandler}
          userEmailHandler={emailHandler}
          registrationHandler={authHandler}
        />
      )}
    </>
  );
};

export default Registration;
