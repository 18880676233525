import React, { ChangeEvent } from "react";
import { Modal, Input, Typography, Form } from "antd";
const { Title } = Typography;

interface FormRegistrationProps {
  visibleLogInForm: boolean;
  handleClose: () => void;
  userEmailHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  registrationHandler: () => void;
}

const FormRegistration: React.FC<FormRegistrationProps> = ({
  visibleLogInForm,
  handleClose,
  userEmailHandler,
  registrationHandler,
}) => {
  return (
    <Modal
      visible={visibleLogInForm}
      title="Регистрация"
      onCancel={handleClose}
      onOk={registrationHandler}
      cancelText="Отмена"
      okText="Регистрация"
    >
      <Form size="large" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <Form.Item
          label={
            <Title level={5} style={{ fontWeight: "normal", marginTop: 5 }}>
              Почта
            </Title>
          }
        >
          <Input
            placeholder="example@mail.com"
            id="email"
            onChange={(event) => userEmailHandler(event)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormRegistration;
