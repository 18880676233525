import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { LoadData } from "../../assets/requests";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/actions/userInfo";
import { setSectionAction } from "../../store/actions/section";
import { CoursesListContent } from "./Course/CoursesList";
import { Divider, Typography, Menu } from "antd";
import { UserList } from "./Users/UserList";
import {AdminCoursesListContent} from "./AdminCourse/AdminCoursesList";

const { Title } = Typography;

export const AdminRoomRoute = "/adminRoom";
export const CoursesListRoute = "/CoursesList";
export const CourseEditRoute = "/CourseEdit";
export const UsersListRoute = "/UsersList";

const AdminRoom: React.FC = () => {
  const auth = useSelector((state: RootState) => state.auth);
  const [currentMenuItem, setCurrentMenuItem] =
    React.useState<string>("Courses");

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (auth) {
      dispatch(setSectionAction(-1));
      Promise.resolve(LoadData(auth.userId)).then((res) =>
        dispatch(setUserInfo(res))
      );
    }
    const curentMenu = localStorage.getItem("curentMenuItem");
    if (curentMenu) setCurrentMenuItem(curentMenu);
  }, []);

  const handleClick = (e: { key: React.SetStateAction<string> }) => {
    setCurrentMenuItem(e.key);
    localStorage.setItem("paginationUsers", "1");
    localStorage.setItem("paginationCourses", "1");
    localStorage.setItem("paginationAdminCourses", "1");
    localStorage.setItem("curentMenuItem", `${e.key}`);
  };

  return (
    <div className="container base-container">
      <Divider>
        <Title level={3}>Кабинет администратора</Title>
      </Divider>
      <Menu
        mode="horizontal"
        selectedKeys={[currentMenuItem]}
        defaultSelectedKeys={[currentMenuItem]}
        onClick={handleClick}
      >
        <Menu.Item key="Courses">Ссылки</Menu.Item>
        {auth.roles.includes("Admin") && (
          <Menu.Item key="Users">Пользователи</Menu.Item>
        )}
        {auth.roles.includes("Admin") && (
          <Menu.Item key="AdminCourse">Курсы</Menu.Item>
            )}
      </Menu>
      {currentMenuItem === "Courses" ? <CoursesListContent /> : currentMenuItem === "Users" ? <UserList /> : <AdminCoursesListContent />}
    </div>
  );
};

export default AdminRoom;
