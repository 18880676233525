import * as React from "react";
import { Alert } from "antd";
import { IAnswer } from "../../assets/types/Types";

interface AnswerProp {
  check: boolean | null;
  selectedAnswer: (answer: IAnswer) => void;
  data: string;
}

const Answer: React.FC<AnswerProp> = ({ check, selectedAnswer, data }) => {
  return (
    <Alert
      // @ts-ignore
      onClick={selectedAnswer}
      message={data}
      className="answer-item"
      type={check === null ? "info" : check ? "success" : "error"}
      style={check === null ? { marginBottom: "10px", marginTop: "10px"} : check ? { marginBottom: "10px", marginTop: "10px", backgroundColor: "#78cd22" } : { marginBottom: "10px", marginTop: "10px", backgroundColor: "#ff4225" }}
    />
  );
};

export default Answer;
